import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Login from '../Login';
import Register from '../Register';
import ForgotPassword from '../ForgotPassword';
import SignedUp from '../SignedUp';
import Sessions from '../Sessions';
import Documents from '../Documents';
import Chat from '../App';
import PageNotFound from '../PageNotFound';
import Clients from '../Clients';
import Screen from '../Screen';
import Settings from '../Settings';
import ChangePassword from '../ChangePassword';
import {
    AuthenticatedRoute,
    PublicRoute,
    AdminRoute,
} from './AuthenticatedRoute';
import Home from '../Home';
import './UrlRoutes.css';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsOfUse from '../TermsOfUse';
import PageTracker from '../PageTracker';

export default function UrlRoutes() {
    // useEffect(() => {
    //     const setVh = () => {
    //         let vh = window.innerHeight * 0.01;
    //         document.documentElement.style.setProperty('--vh', `${vh}px`);
    //     };
    //     setVh();
    //     window.addEventListener('resize', setVh);
    //     return () => window.removeEventListener('resize', setVh);
    // }, []);
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/login"
                    element={
                        <PublicRoute>
                            <Login />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/register"
                    element={
                        <PublicRoute>
                            <Register />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/forgot_password"
                    element={
                        <PublicRoute>
                            <ForgotPassword />
                        </PublicRoute>
                    }
                />
                <Route
                    path="/signedup"
                    element={
                        <PublicRoute>
                            <SignedUp />
                        </PublicRoute>
                    }
                />

                <Route
                    path="/settings"
                    element={
                        <AuthenticatedRoute>
                            <Settings />
                        </AuthenticatedRoute>
                    }></Route>
                <Route
                    path="/sessions"
                    element={
                        <AuthenticatedRoute>
                            <Sessions />
                        </AuthenticatedRoute>
                    }></Route>
                <Route
                    path="/documents"
                    element={
                        <AuthenticatedRoute>
                            <Documents />
                        </AuthenticatedRoute>
                    }></Route>
                <Route
                    path="/change_password"
                    element={
                        <AuthenticatedRoute>
                            <ChangePassword />
                        </AuthenticatedRoute>
                    }></Route>
                <Route
                    path="/clients"
                    element={
                        <AdminRoute>
                            <Clients />
                        </AdminRoute>
                    }></Route>
                <Route
                    path="/page_tracker"
                    element={
                        <AdminRoute>
                            <PageTracker />
                        </AdminRoute>
                    }></Route>
                <Route path="/chat/:companyParam" element={<Chat />} />
                <Route
                    path="page_does_not_exist"
                    element={<PageNotFound />}></Route>
                <Route path="/" element={<Home />} />
                <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                <Route path="/terms_of_use" element={<TermsOfUse />} />
                <Route path="*" element={<Navigate to="/sessions" />} />
                {/* <Route
                    path="/"
                    element={
                        <AuthenticatedRoute>
                            <Home />
                        </AuthenticatedRoute>
                    }></Route> */}
            </Routes>
        </BrowserRouter>
    );
}
