import { useState, useEffect, forwardRef, useContext } from 'react';
// import { Link } from 'react-router-dom';
import {
    Box,
    Flex,
    Text,
    Spacer,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar,
    Link,
    Divider,
    Image,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './utils/AuthContext';
import AppLogo from './logo.png';

const PrivacyHeader = forwardRef((props, ref) => {
    const { token, setToken } = useContext(AuthContext);
    const navigate = useNavigate();
    function handleLogout() {
        localStorage.removeItem('userInfo');
        setToken(null);
        navigate('/login');
    }
    const sidebarWidth = useBreakpointValue({
        base: '30px',
        md: '30px',
        lg: '60px',
    });
    return (
        // <Flex
        //     zIndex="sticky"
        //     h="100%"
        //     w="100%"
        //     size="sm"
        //     ref={ref}
        //     borderBottomWidth="1px"
        //     borderBottomColor="gray.200"
        //     boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        //     as="nav"
        //     align="center"
        //     justify="space-between"
        //     wrap="wrap"
        //     padding="1.5rem"
        //     // bg="blue.500"
        //     color="white">
        //     <Flex align="center" mr={5}>
        //         <Link to="/">
        //             <Text
        //                 as="h1"
        //                 fontSize="xl"
        //                 fontWeight="bold"
        //                 letterSpacing={'-.1rem'}></Text>
        //         </Link>
        //     </Flex>

        //     <Spacer />

        //     <Flex align="center">
        //         <Menu zIndex="sticky">
        //             <MenuButton>
        //                 <Image
        //                     w={8}
        //                     h={8}
        //                     alt="..."
        //                     src={`https://ui-avatars.com/api/?name=${
        //                         JSON.parse(localStorage.getItem('userInfo'))
        //                             ?.name
        //                     } &background=A88B5C&bold=true&rounded=true`}
        //                 />
        //             </MenuButton>
        //             <MenuList zIndex="sticky">
        //                 <MenuItem>
        //                     <Text color="black" fontWeight="bold">
        //                         {
        //                             JSON.parse(localStorage.getItem('userInfo'))
        //                                 ?.name
        //                         }
        //                     </Text>
        //                 </MenuItem>

        //                 <hr></hr>

        //                 <MenuItem color="black" onClick={handleLogout}>
        //                     Logout
        //                 </MenuItem>
        //             </MenuList>
        //         </Menu>
        //     </Flex>
        // </Flex>
        <Box
            zIndex="100"
            // w="100%"
            // h="100%"
            ref={ref}
            borderBottomWidth="1px"
            borderBottomColor="gray.200"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            as="header"
            position="fixed"
            top="0"
            // left={sidebarWidth}
            left={'0'}
            right="0"
            height="64px"
            padding="1.5rem"
            // bg="blue.500"
            color="white"
            bg="white"
            display="flex"
            alignItems="center"
            justifyContent="flex-start">
            {/* Left side: Logo */}
            <Box display="flex" alignItems="center">
                <Link>
                    <Image
                        ml="5"
                        alignSelf={'center'}
                        maxW="80%"
                        maxH="80%"
                        src={AppLogo}
                        color="black"
                    />
                </Link>
            </Box>
        </Box>
    );
});

export default PrivacyHeader;
