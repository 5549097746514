import { useState, useEffect, forwardRef } from 'react';
// import { Link } from 'react-router-dom';
import {
    Box,
    Flex,
    Text,
    Spacer,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar,
    Link,
    Divider,
    Image,
    Icon,
} from '@chakra-ui/react';
import { MdAutoAwesome } from 'react-icons/md';
import MobileScreen from './img/mobile_screen.png';
import { BASE_URL } from './utils/config';

import Laptop from './laptop_screen.png';

const HeaderScreen = forwardRef((props, ref) => {
    const { headerValues } = props;

    return (
        <Flex
            bg={headerValues.headerColor}
            zIndex="sticky"
            height="100%"
            size="sm"
            ref={ref}
            // borderBottomWidth="1px"
            // borderBottomColor="gray.200"
            // boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding="0.2em"
            // h="0.7rem"
            // bg="blue.500"
            color="white">
            <Flex align="center" height={'100%'} justify={'center'}>
                <Link
                    // href={headerValues.logoLink ? headerValues.logoLink : ''}

                    h="100%"
                    w="100%">
                    <Box display="flex" w="100%" h="100%" m={0}>
                        {headerValues.logo ? (
                            <Image
                                maxW="80%"
                                ml="3"
                                maxH="95%"
                                size="sm"
                                src={
                                    headerValues.logoPreview
                                        ? URL.createObjectURL(headerValues.logo)
                                        : `${BASE_URL}${headerValues.logo}`
                                }
                                // URL.createObjectURL(headerValues.logo)
                                color="black"
                            />
                        ) : (
                            <Image
                                w="100%"
                                h="100%"
                                size="sm"
                                src={Laptop}
                                color="black"
                            />
                        )}
                    </Box>
                </Link>
            </Flex>
        </Flex>
    );
});

export default HeaderScreen;
