// import { useState, useEffect, forwardRef } from 'react';
// // import { Link } from 'react-router-dom';
// import {
//     Box,
//     Flex,
//     Text,
//     Spacer,
//     Menu,
//     MenuButton,
//     MenuList,
//     MenuItem,
//     Avatar,
//     Link,
//     Divider,
//     Image,
//     Icon,
// } from '@chakra-ui/react';
// import { MdAutoAwesome } from 'react-icons/md';
// import { useNavigate } from 'react-router-dom';

// const HeaderChat = forwardRef((props, ref) => {
//     const navigate = useNavigate();
//     function handleLogout() {
//         localStorage.removeItem('userInfo');
//         navigate('/login');
//     }
//     return (
//         <Flex
//             bg="gray.500"
//             zIndex="sticky"
//             size="sm"
//             ref={ref}
//             borderBottomWidth="1px" // Adjust thickness as needed
//             borderBottomColor="gray.200" // Adjust color as needed
//             boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
//             as="nav"
//             align="center"
//             justify="space-between"
//             wrap="wrap"
//             padding="1.5rem"
//             // bg="blue.500"
//             color="white">
//             <Flex align="center" mr={5}>
//                 <Link to="/">
//                     {/* <Text
//                         as="h1"
//                         fontSize="xl"
//                         fontWeight="bold"
//                         letterSpacing={'-.1rem'}>
//                         {props?.brandText}
//                     </Text> */}
//                     <Icon
//                         as={MdAutoAwesome}
//                         width="20px"
//                         height="20px"
//                         color="black"
//                     />
//                 </Link>
//             </Flex>
//         </Flex>
//     );
// });

// export default HeaderChat;

import { useState, useEffect, forwardRef } from 'react';
// import { Link } from 'react-router-dom';
import {
    Box,
    Flex,
    Text,
    Spacer,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar,
    Link,
    Divider,
    Image,
    Icon,
} from '@chakra-ui/react';
import { MdAutoAwesome } from 'react-icons/md';
import MobileScreen from './img/mobile_screen.png';
import { BASE_URL } from './utils/config';

import Laptop from './laptop_screen.png';

const HeaderChat = forwardRef((props, ref) => {
    const { headerValues } = props;

    return (
        // <Flex
        //     bg={headerValues.headerColor}
        //     zIndex="sticky"
        //     height="100%"
        //     size="sm"
        //     ref={ref}

        //     boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
        //     as="nav"
        //     align="center"
        //     justify="space-between"
        //     wrap="wrap"
        //     padding="0.2em"

        //     color="white">
        //     <Flex align="center" h={'100%'} justify={'center'}>
        //         <Link
        //             href={headerValues.logoLink ? headerValues.logoLink : ''}
        //             h="100%"
        //             w="100%">
        //             <Box w="100%" h="100%" m={0}>
        //                 {headerValues.logo ? (
        //                     <Image
        //                         w="100%"
        //                         h="100%"
        //                         size="sm"
        //                         src={`${BASE_URL}${headerValues.logo}`}

        //                         color="black"
        //                     />
        //                 ) : (
        //                     <Image
        //                         w="100%"
        //                         h="100%"
        //                         size="sm"
        //                         src={Laptop}
        //                         color="black"
        //                     />
        //                 )}
        //             </Box>
        //         </Link>
        //     </Flex>
        // </Flex>
        <Flex
            bg={headerValues.headerColor}
            zIndex="sticky"
            height="60px"
            // boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            padding="0.2em"
            color="white">
            <Flex
                align="center"
                h="100%"
                justify="center"
                // border="none"

                boxShadow="none">
                <Link h="100%" w="100%" border="none" textDecoration={'none'}>
                    <Box
                        display="flex"
                        w="100%"
                        h="100%"
                        m={0}
                        boxShadow="none">
                        {headerValues.logo ? (
                            <Image
                                bg={headerValues.headerColor}
                                border="none"
                                ml="5"
                                alignSelf={'center'}
                                maxW="80%"
                                maxH="80%"
                                src={`${BASE_URL}${headerValues.logo}`}
                                // src={MdAutoAwesome}
                                color="black"
                                boxShadow="none"
                            />
                        ) : (
                            <Image
                                w="100%"
                                h="100%"
                                src={MdAutoAwesome}
                                color="black"
                            />
                        )}
                    </Box>
                </Link>
            </Flex>
            {/* <Heading size="md">Chat Box</Heading> */}
        </Flex>
    );
});

export default HeaderChat;
