import { useState, useEffect, useContext } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Link,
    Text,
    Divider,
    useColorModeValue,
    Alert,
    AlertIcon,
    ChakraProvider,
} from '@chakra-ui/react';
import { EmailIcon, LockIcon } from '@chakra-ui/icons';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthContext } from './utils/AuthContext'; // Assuming AuthContext is available
import { useNavigate } from 'react-router-dom'; // Assuming you are using react-router-dom
import theme from './theme';

const ForgotPassword = () => {
    const {
        //     register,
        //     userInfo,
        sendOtp,
        toastrMsg,
        toastrError,
        setToastrMsg,
        setToastrError,
        toastrEmailMsg,
        toastrEmailError,
        setToastrEmailMsg,
        setToastrEmailError,
        toastrOtpMsg,
        toastrOtpError,
        setToastrOtpMsg,
        setToastrOtpError,
        verifyOtp,
        setPassword,
        otpSent,
        otpVerifyError,
        otpVerifyMsg,
        setOtpVerifyError,
        setOtpVerifyMsg,
    } = useContext(AuthContext);
    const navigate = useNavigate();
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [username, setUsername] = useState('');
    const [passStrength, setPassStrength] = useState(1);
    const [disabled, setDisabled] = useState(true);
    const [emailDisable, setEmailDisable] = useState(false);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(null);
    const [emailSent, setEmailSent] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const navigate = useNavigate();

    function checkPasswordStrength(password) {
        // Define regex patterns to check for uppercase, lowercase, numbers, and special characters
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(
            password
        );

        // Check the length of the password
        const isLengthValid = password.length >= 8;

        // Determine the strength based on the criteria
        if (
            hasUppercase &&
            hasLowercase &&
            hasNumbers &&
            hasSpecialChars &&
            isLengthValid
        ) {
            setPassStrength(3);
        } else if (
            (hasUppercase || hasLowercase) &&
            hasNumbers &&
            isLengthValid
        ) {
            setPassStrength(2);
        } else {
            setPassStrength(1);
        }
    }

    useEffect(() => {
        if (toastrMsg) {
            navigate('/login');
            toast.success(toastrMsg);
            setToastrMsg(null);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setIsLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    useEffect(() => {
        if (toastrEmailMsg) {
            setEmailDisable(true);

            toast.success(toastrEmailMsg);
            setToastrEmailMsg(null);
        } else if (toastrEmailError) {
            toast.error(toastrEmailError);
            setToastrEmailError(null);
        }
        setIsLoading(false);
    }, [
        toastrEmailMsg,
        setToastrEmailMsg,
        toastrEmailError,
        setToastrEmailError,
    ]);

    useEffect(() => {
        if (otpVerifyMsg) {
            toast.success(otpVerifyMsg);
            setOtpVerifyMsg(null);
        } else if (otpVerifyError) {
            toast.error(otpVerifyError);
            setOtpVerifyError(null);
        }
        setIsLoading(false);
    }, [otpVerifyMsg, setOtpVerifyMsg, otpVerifyError, setOtpVerifyError]);

    useEffect(() => {
        if (toastrOtpMsg) {
            setEmailSent(true);
            toast.success(toastrOtpMsg);

            setToastrOtpMsg(null);
        } else if (toastrOtpError) {
            toast.error(toastrOtpError);

            setToastrOtpError(null);
        }
        setIsLoading(false);
    }, [toastrOtpMsg, setToastrOtpMsg, toastrOtpError, setToastrOtpError]);

    function checkComplete() {
        if (password1 !== '' && password2 !== '') {
            setDisabled(false);
        }
    }

    function handleSendOtp() {
        setIsLoading(true);
        if (email !== '' && otp !== '') {
            verifyOtp(email, otp);
        }
    }

    function handleClick(password1, password2) {
        setIsLoading(true);
        if (password1 !== '' && password2 !== '') {
            if (password1 !== password2) {
                toast.error('Passwords did not match');
            } else if (password1 === password2 && passStrength === 1) {
                toast.error(
                    'Your password is weak. Set a strong password of at least 8 characters long with a combination of one uppercase, one lowercase, one number, and one special character'
                );
                setIsLoading(false);
            } else if (password1 === password2) {
                setPassword(email, password1);
            }
        }
    }

    // useEffect(() => {
    //     if (JSON.parse(localStorage.getItem('userToken'))) {
    //         navigate('/admin');

    //         setUsername('');
    //         setPassword('');
    //     }
    // });

    // useEffect(() => {
    //     if (userInfo !== null) {
    //     }
    //     if (JSON.parse(localStorage.getItem('userInfo'))?.token) {
    //     }
    // }, [userInfo]);

    return (
        <ChakraProvider>
            {!emailSent ? (
                <>
                    <ToastContainer />
                    <Flex justify="center" align="center" h="100vh">
                        <Flex
                            w={{
                                base: '90vw',
                                sm: '90vw',
                                md: '70vw',
                                lg: '70vw',
                                xl: '40vw',
                            }}
                            direction="column"
                            p={{ base: '4', xl: '8' }}
                            rounded="md"
                            shadow="md"
                            bg="white">
                            <FormControl mt="4">
                                <FormLabel>Email</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <EmailIcon color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                        type="email"
                                        autoComplete="new-email"
                                        isDisabled={otpSent}
                                        value={email}
                                        onChange={(event) => {
                                            setEmail(event.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl mt="4" hidden={!otpSent}>
                                <FormLabel>OTP</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <LockIcon color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                        isDisabled={emailDisable ? false : true}
                                        type="number"
                                        inputMode="numeric"
                                        autoComplete="new-otp"
                                        value={otp}
                                        onChange={(event) => {
                                            setOtp(event.target.value);
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>
                            {otpSent ? (
                                <Button
                                    isLoading={isLoading}
                                    isDisabled={otp ? false : true}
                                    mx="auto"
                                    mt="8"
                                    colorScheme="blue"
                                    onClick={() => {
                                        setIsLoading(true);
                                        handleSendOtp(email);
                                    }}>
                                    Verify OTP
                                </Button>
                            ) : (
                                <Button
                                    isLoading={isLoading}
                                    isDisabled={!email ? true : false}
                                    mx="auto"
                                    mt="8"
                                    colorScheme="blue"
                                    onClick={() => {
                                        setIsLoading(true);
                                        sendOtp(email);
                                    }}>
                                    Send OTP
                                </Button>
                            )}

                            <Divider my="4" />
                            <Text textAlign="center" color="gray.500" mb="4">
                                Remembered Password?{' '}
                                <Link href="/login" color="blue.500">
                                    Login
                                </Link>
                            </Text>
                        </Flex>
                    </Flex>
                </>
            ) : (
                <>
                    <ToastContainer />
                    <Flex justify="center" align="center" h="100vh">
                        <Flex
                            w={{
                                base: '90vw',
                                sm: '90vw',
                                md: '70vw',
                                lg: '70vw',
                                xl: '40vw',
                            }}
                            direction="column"
                            p={{ base: '4', xl: '8' }}
                            rounded="md"
                            shadow="md"
                            bg="white">
                            {password1 && (
                                <Text
                                    mt="2"
                                    mb="4"
                                    color="gray.500"
                                    fontSize="sm">
                                    Password Strength:{' '}
                                    {passStrength === 1 && (
                                        <Text
                                            as="span"
                                            color="red.500"
                                            fontWeight="bold">
                                            Weak
                                        </Text>
                                    )}
                                    {passStrength === 2 && (
                                        <Text
                                            as="span"
                                            color="yellow.500"
                                            fontWeight="bold">
                                            Moderate
                                        </Text>
                                    )}
                                    {passStrength === 3 && (
                                        <Text
                                            as="span"
                                            color="green.500"
                                            fontWeight="bold">
                                            Strong
                                        </Text>
                                    )}
                                </Text>
                            )}
                            <FormControl mt="4">
                                <FormLabel>New Password</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <LockIcon color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                        placeholder="New Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={password1}
                                        onChange={(event) => {
                                            setPassword1(event.target.value);
                                            checkComplete();
                                            checkPasswordStrength(
                                                event.target.value
                                            );
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Re-Enter Password</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <LockIcon color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                        placeholder="Re-Enter Password"
                                        type="password"
                                        autoComplete="new-password"
                                        value={password2}
                                        onChange={(event) => {
                                            setPassword2(event.target.value);
                                            checkComplete();
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>
                            <Button
                                isLoading={isLoading}
                                isDisabled={!password1 || !password2}
                                mt="8"
                                mx="auto"
                                colorScheme="blue"
                                disabled={disabled}
                                onClick={() => {
                                    setIsLoading(true);
                                    handleClick(password1, password2);
                                }}>
                                Set Password
                            </Button>
                        </Flex>
                    </Flex>
                </>
            )}
        </ChakraProvider>
    );
};

export default ForgotPassword;
