import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import UrlRoutes from './routes/UrlRoutes';
import { AuthProvider } from './utils/AuthContext';
import TagManager from 'react-gtm-module';

TagManager.initialize({
    gtmId: 'AW-16595674367',
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AuthProvider>
            <UrlRoutes />
        </AuthProvider>
        {/* <ScreenApp /> */}
        {/* <BrowserRouter>
            <Screen />
        </BrowserRouter> */}
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
