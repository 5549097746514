import MessageBoxChat from './components/MessageBox';
import React, { useEffect, useState, useRef } from 'react';
import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Flex,
    Icon,
    Img,
    Input,
    Text,
    assignRef,
    useColorModeValue,
    useInputGroupStyles,
    Container,
    InputRightElement,
    VStack,
} from '@chakra-ui/react';

import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import HeaderScreen from './HeaderScreen';

export default function ScreenApp(props) {
    const { boxHeight, screenValues } = props;
    const [inputCode, setInputCode] = useState('');
    const headerRef = useRef();
    const [headerHeight, setHeaderHeight] = useState(
        headerRef?.current?.offsetHeight
    );

    const [loading, setLoading] = useState(false);

    const textColor = useColorModeValue('navy.700', 'white');
    const placeholderColor = useColorModeValue(
        { color: 'gray.500' },
        { color: 'whiteAlpha.600' }
    );

    const chatBoxRef = useRef();

    useEffect(() => {
        setHeaderHeight(headerRef?.current?.offsetHeight);
    }, [headerRef]);

    const chatHistory = [
        {
            prompt: 'Do you offer discounts?',
            response: 'Yes sure, we offer discount on bulk purchase.',
        },
        {
            prompt: 'I am looking to buy ten pieces',
            response:
                'Great!! We would be happy to offer you a 20% disount on the invoice amount',
        },
    ];

    const handleChange = (event) => {
        setInputCode(event.target.value);
    };

    return (
        <Box h="100%" w="100%" bg={screenValues.bodyColor}>
            <Box w="100%" h="10%" bg={screenValues?.headerColor}>
                <HeaderScreen
                    headerValues={{
                        headerColor: screenValues.headerColor,
                        logo: screenValues.logo,
                        logoLink: screenValues.logoLink,
                        logoPreview: screenValues.logoPreview,
                    }}
                />
            </Box>
            <Box h="90%" mx={5} bg={screenValues.bodyColor}>
                <Box h="90%" w="100%">
                    <Box h="100%" w="100%" py={1} px={2}>
                        <Flex
                            ref={chatBoxRef}
                            direction="column"
                            h="100%"
                            overflow="scroll"
                            display="flex"
                            css={{
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                            }}>
                            {screenValues.question && (
                                <Flex
                                    mr="auto"
                                    mb="1em"
                                    maxW="90%"
                                    float="right">
                                    <Flex
                                        bg={screenValues.responseMsgBgColor}
                                        borderRadius="4px"
                                        p="0.4em"
                                        border="none"
                                        w="100%"
                                        fontSize={{
                                            base: 'sm',
                                        }}>
                                        <Text
                                            mb="0"
                                            pb="0"
                                            whiteSpace="pre-wrap"
                                            color={
                                                screenValues.responseMsgColor
                                            }
                                            fontWeight="600em"
                                            fontSize={{
                                                base: 'sm',
                                            }}>
                                            {screenValues.question}
                                        </Text>
                                    </Flex>
                                </Flex>
                            )}

                            {chatHistory.map((x, i) => {
                                return (
                                    <React.Fragment key={i}>
                                        <Flex
                                            ml="auto"
                                            justify={'flex-end'}
                                            float="left"
                                            maxW="75%"
                                            mb="1em">
                                            <Flex
                                                bg={
                                                    screenValues.inputMsgBgColor
                                                }
                                                borderRadius="4px"
                                                p="0.4em"
                                                border="none"
                                                w="100%"
                                                zIndex={'2'}>
                                                <Text
                                                    mb="0"
                                                    pb="0"
                                                    color={
                                                        screenValues.inputMsgColor
                                                    }
                                                    fontWeight="600em"
                                                    fontSize={{
                                                        base: 'sm',
                                                    }}>
                                                    {x?.prompt}
                                                </Text>
                                            </Flex>
                                        </Flex>

                                        <Flex
                                            mr="auto"
                                            mb="1em"
                                            maxW="90%"
                                            float="right">
                                            <Flex
                                                bg={
                                                    screenValues.responseMsgBgColor
                                                }
                                                borderRadius="4px"
                                                p="0.4em"
                                                border="none"
                                                w="100%"
                                                fontSize={{
                                                    base: 'sm',
                                                }}>
                                                <Text
                                                    mb="0"
                                                    pb="0"
                                                    color={
                                                        screenValues.responseMsgColor
                                                        // 'black'
                                                    }
                                                    fontWeight="600em"
                                                    fontSize={{
                                                        base: 'sm',
                                                    }}>
                                                    {x?.response}
                                                </Text>
                                            </Flex>
                                        </Flex>
                                    </React.Fragment>
                                );
                            })}
                        </Flex>
                    </Box>
                </Box>
                <Box h="10%" w="100%" bg={screenValues.bodyColor}>
                    <Box h="100%" w="100%">
                        <Flex
                            ps="0.3em"
                            alignItems="center"
                            h="90%"
                            bg={screenValues.inputTextboxBgColor}
                            borderRadius="50px">
                            <Input
                                py="0.2em"
                                bg="inherit"
                                h="80%"
                                flex="1"
                                border="none"
                                boxShadow={'none'}
                                fontSize="sm"
                                value={inputCode}
                                _focus={{ border: 'none', boxShadow: 'none' }}
                                _placeholder={placeholderColor}
                                placeholder="Type your message here..."
                                onChange={handleChange}
                                mr="1em"
                            />

                            <Button
                                size="sm"
                                variant="primary"
                                fontSize="sm"
                                borderRadius="50%"
                                color={screenValues.sendBtnColor}
                                _hover={{
                                    boxShadow:
                                        '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                                    bg: `${screenValues.sendBtnBgColor}`,
                                    _disabled: {
                                        bg: 'lightgray',
                                    },
                                }}
                                isLoading={loading ? true : false}
                                h="100%"
                                w="auto">
                                ↑
                            </Button>
                        </Flex>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
