import { useState, useContext, useEffect } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    ChakraProvider,
    useColorModeValue,
    Divider,
    Text,
    Link,
    Box,
    Heading,
    Image,
    Center,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import theme from './theme';
import { AuthContext } from './utils/AuthContext';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { toast, ToastContainer } from 'react-toastify';
import Logo from './assets/img/logo.png';

const Register = () => {
    const {
        register,
        isRegistered,
        token,
        verifyOtp,
        toastrMsg,
        toastrError,
        setToastrMsg,
        setToastrError,
        checkGRegister,
        hasGSigned,
        // gRegistered,
        googleRegistered,
        addPageRecord,
    } = useContext(AuthContext);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [gRegistered, setGRegistered] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showBox, setShowBox] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        addPageRecord('signup', window.location.href);
        setGRegistered(false);
    }, []);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setIsLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    const emailValidator = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    const handleRegister = (gSign = false) => {
        setIsLoading(true);
        if (name !== '' && companyName !== '' && email !== '') {
            if (gSign) {
                register(email, name, companyName, gSign);
            } else {
                if (emailValidator(email)) {
                    register(email, name, companyName);
                } else {
                    toast.error('Enter a valid email');
                    setIsLoading(false);
                }
            }
            // navigate("/auth/register/signedIn");
        }
    };

    const handleOnSuccess = (response) => {
        const idToken = response?.credential;

        if (idToken) {
            const decodeToken = jwtDecode(idToken);
            const email = decodeToken?.email;
            const firstName = decodeToken?.given_name;
            const lastName = decodeToken?.family_name;
            setEmail(email);
            setName(`${firstName} ${lastName}`);

            checkGRegister(email);
        }
    };

    useEffect(() => {
        if (hasGSigned === 'yes') {
            setGRegistered(true);
        } else if (hasGSigned === 'no') {
            setGRegistered(false);
            setEmail('');
            setName('');
        }
    }, [hasGSigned]);

    useEffect(() => {
        if (isRegistered) {
            navigate('/signedup');
        }
    }, [isRegistered]);

    useEffect(() => {
        if (googleRegistered) {
            const chatLength = JSON.parse(
                localStorage.getItem('userInfo')
            )?.chat_length;
            if (chatLength > 0) {
                navigate('/sessions');
            } else {
                navigate('/settings');
            }
        }
    }, [googleRegistered]);

    // useEffect(() => {
    //     if (gRegistered) {
    //         navigate('/signedup');
    //     }
    // }, [gRegistered]);

    const handleOnFailure = (response) => {
        toast.error('Could Not Sign In');
    };

    const checkRegisterComplete = () => {
        if (email !== '' && name !== '' && companyName !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const checkQComplete = () => {
        if (companyName !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    useEffect(() => {
        checkRegisterComplete();
        checkQComplete();
    }, [name, email, companyName]);

    return (
        <ChakraProvider>
            <ToastContainer />
            {!gRegistered ? (
                <Flex
                    justify="center"
                    align="center"
                    h="100vh"
                    // bg={useColorModeValue('gray.100', 'gray.800')}
                >
                    <Flex
                        w={{
                            base: '90vw',
                            sm: '90vw',
                            md: '70vw',
                            lg: '70vw',
                            xl: '40vw',
                        }}
                        // maxW="400px"
                        direction="column"
                        p={{ base: '4', xl: '8' }}
                        rounded="md"
                        shadow="md"
                        bg="white">
                        <Center>
                            <Image h={8} src={Logo} />
                        </Center>
                        <Heading size="md" align="center" mt="4">
                            Sign Up
                        </Heading>
                        <FormControl mt="4" align="center">
                            <GoogleOAuthProvider clientId="182264721206-qo0r70thqj1a94eb85vjun747cnvjss0.apps.googleusercontent.com">
                                <GoogleLogin
                                    size="large"
                                    text="signup_with"
                                    theme="filled_blue"
                                    itp_support={false}
                                    // type="standard"
                                    className="btn ms-1 my-4 font-weight-bold atlas-cta cta-green"
                                    context="signup"
                                    // use_fedcm_for_prompt={false}
                                    onSuccess={handleOnSuccess}
                                    onError={handleOnFailure}
                                    autoSelect={false}></GoogleLogin>
                            </GoogleOAuthProvider>
                        </FormControl>

                        <Button
                            size="sm"
                            mt="3"
                            variant={'outline'}
                            textAlign="center"
                            mb="4"
                            cursor={'pointer'}
                            onClick={() => {
                                setShowBox(!showBox);
                            }}>
                            Or sign up with Email
                        </Button>
                        <Box hidden={!showBox} mb="3">
                            <FormControl mt="4">
                                <FormLabel>Name</FormLabel>
                                <Input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Email</FormLabel>
                                <Input
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Company Name</FormLabel>
                                <Input
                                    value={companyName}
                                    onChange={(e) =>
                                        setCompanyName(e.target.value)
                                    }
                                />
                            </FormControl>
                            <Flex justify={'center'}>
                                <Button
                                    isDisabled={disabled}
                                    isLoading={isLoading}
                                    alignItem={'center'}
                                    mt="9"
                                    mx="auto"
                                    colorScheme="blue"
                                    onClick={() => {
                                        handleRegister(false);
                                    }}>
                                    Register
                                </Button>
                            </Flex>
                        </Box>

                        <Button
                            mt="4"
                            onClick={() => {
                                navigate('/login');
                            }}
                            size="sm"
                            variant="outline"
                            textAlign="center"
                            // color="gray.500"
                            mb="4">
                            Already registered?{'   '}
                            Sign In
                        </Button>
                    </Flex>
                </Flex>
            ) : (
                <Flex
                    justify="center"
                    align="center"
                    h="100vh"
                    // bg={useColorModeValue('gray.100', 'gray.800')}
                >
                    <Flex
                        w={{
                            base: '90vw',
                            sm: '90vw',
                            md: '70vw',
                            lg: '70vw',
                            xl: '40vw',
                        }}
                        // maxW="400px"
                        direction="column"
                        p={{ base: '4', xl: '8' }}
                        rounded="md"
                        shadow="md"
                        bg="white">
                        <Text textAlign="center" color="gray.500" mb="4">
                            Just a question .....
                        </Text>
                        <FormControl mt="4">
                            <FormLabel>What is your company name?</FormLabel>
                            <Input
                                placeholder="Company Name"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        </FormControl>
                        <Button
                            isDisabled={disabled}
                            isLoading={isLoading}
                            mt="9"
                            mx="auto"
                            colorScheme="blue"
                            onClick={() => {
                                handleRegister(true);
                            }}>
                            Submit
                        </Button>
                    </Flex>
                </Flex>
            )}
        </ChakraProvider>
    );
};

export default Register;
