import { useState, useEffect, useContext } from 'react';
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Link,
    ChakraProvider,
    useColorModeValue,
    Divider,
    Text,
    Box,
    Heading,
    Center,
    Image,
} from '@chakra-ui/react';
import Logo from './assets/img/logo.png';
// import { ChakraProvider } from '@chakra-ui/react';
import { AuthContext } from './utils/AuthContext';
import theme from './theme';
import { useNavigate } from 'react-router-dom';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { toast, ToastContainer } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import { add } from 'date-fns';

const Login = () => {
    const {
        login,
        token,
        userType,
        checkEmail,
        hasGSigned,
        toastrMsg,
        setToastrMsg,
        toastrError,
        setToastrError,
        addPageRecord,
    } = useContext(AuthContext);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [showBox, setShowBox] = useState(false);

    const navigate = useNavigate();

    const emailValidator = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    };

    useEffect(() => {
        addPageRecord('login', window.location.href);
    }, []);

    function handleLogin(username, password) {
        setIsLoading(true);
        if (username !== '' && password !== '') {
            if (emailValidator(username)) {
                login(username, password);
            } else {
                toast.error('Enter a valid email');
                setIsLoading(false);
            }
        }
    }

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setIsLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    const handleOnSuccess = (response) => {
        const idToken = response?.credential;

        if (idToken) {
            const decodeToken = jwtDecode(idToken);
            const email = decodeToken?.email;
            // const firstName = decodeToken?.given_name;
            // const lastName = decodeToken?.family_name;

            checkEmail(email);
        }
    };

    const handleOnFailure = (response) => {
        toast.error('Could Not Sign In');
    };

    const checkComplete = () => {
        if (username !== '' && password !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    useEffect(() => {
        checkComplete();
    }, [password, username]);

    useEffect(() => {
        if (token) {
            setUsername('');
            setPassword('');
            const chatLength = JSON.parse(
                localStorage.getItem('userInfo')
            )?.chat_length;
            if (chatLength > 0) {
                navigate('/sessions');
            } else {
                navigate('/settings');
            }
        }
    }, [token]);

    return (
        <ChakraProvider>
            <ToastContainer />
            <Flex justify="center" align="center" h="100vh">
                <Flex
                    w={{
                        base: '90vw',
                        sm: '90vw',
                        md: '70vw',
                        lg: '70vw',
                        xl: '40vw',
                    }}
                    direction="column"
                    p={{ base: '4', xl: '8' }}
                    rounded="md"
                    shadow="md"
                    bg="white">
                    <Center>
                        <Image h={8} src={Logo} />
                    </Center>
                    <Heading size="md" align="center" mt="4">
                        Sign In
                    </Heading>
                    <FormControl mt="4" align="center">
                        <GoogleOAuthProvider clientId="182264721206-qo0r70thqj1a94eb85vjun747cnvjss0.apps.googleusercontent.com">
                            <GoogleLogin
                                // href=""
                                theme="filled_blue"
                                className="btn ms-1 my-4 font-weight-bold atlas-cta cta-green"
                                onSuccess={handleOnSuccess}
                                onError={handleOnFailure}
                                // useOneTap
                                autoSelect={false}
                            />
                        </GoogleOAuthProvider>
                    </FormControl>

                    <Button
                        size="sm"
                        variant={'outline'}
                        textAlign="center"
                        // color="gray.500"
                        my="4"
                        onClick={() => {
                            setShowBox(!showBox);
                        }}>
                        Or sign in with Email
                    </Button>
                    <Box hidden={!showBox}>
                        <FormControl mt="4">
                            <FormLabel>Email</FormLabel>
                            <Input
                                type="email"
                                autoComplete="new-email"
                                value={username}
                                onChange={(event) =>
                                    setUsername(event.target.value)
                                }
                            />
                        </FormControl>
                        <FormControl mt="4">
                            <FormLabel>Password</FormLabel>
                            <Input
                                type="password"
                                autoComplete="new-password"
                                value={password}
                                onChange={(event) =>
                                    setPassword(event.target.value)
                                }
                            />
                        </FormControl>
                        <Flex justify={'center'}>
                            <Button
                                isLoading={isLoading}
                                isDisabled={disabled}
                                mt="8"
                                mx="auto"
                                colorScheme="blue"
                                onClick={() => handleLogin(username, password)}>
                                Sign in
                            </Button>
                        </Flex>
                    </Box>

                    <Flex justify="space-between" mt="4">
                        <Link color="gray.500" href="/forgot_password">
                            Forgot password?
                        </Link>
                        <Link color="gray.500" href="/register">
                            Create new account
                        </Link>
                    </Flex>
                    <Button
                        mt="4"
                        onClick={() => {
                            navigate('/register');
                        }}
                        size="sm"
                        variant="outline"
                        textAlign="center"
                        // color="gray.500"
                        mb="4">
                        New user? Sign Up
                    </Button>
                </Flex>
            </Flex>
        </ChakraProvider>
    );
};

export default Login;
