/* global dataLayer gtag */
import React, { useState, useEffect, useContext } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/bootstrap/js/bootstrap.bundle.min.js';
import './assets/js/main.js';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
    faCalendar,
    faCreditCard,
    faGlobe,
    faHandPointer,
    faDesktop,
    faUsers,
    faArrowUp,
    faCircleCheck,
    faMapMarker,
    faEnvelope,
    faCheckCircle,
    faArrowAltCircleRight,
} from '@fortawesome/free-solid-svg-icons';

import { useNavigate, Link } from 'react-router-dom';

import logo from './assets/img/logo.png';
import heroBgLight from './assets/img/hero-bg-light.webp';
import infoBotGif from './assets/img/infoBot_GIF.gif';
import sec1 from './assets/img/sc-1.png';
import sec2 from './assets/img/sc-2.png';
import sec3 from './assets/img/sc-3.png';
import tab1 from './assets/img/tabs-1.png';
import tab2 from './assets/img/tabs-2.png';
import tab3 from './assets/img/tabs-3.png';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { AuthContext } from './utils/AuthContext.js';

const App = () => {
    const [currency, setCurrency] = useState('USD');
    const [userToken, setUserToken] = useState('');
    const price = currency === 'USD' ? '$10 / month' : '₹500 / month';

    const { setToken, addPageRecord } = useContext(AuthContext);

    const navigate = useNavigate();

    const changePrice = (e) => {
        setCurrency(e.target.value);
    };

    function handleLogout() {
        localStorage.removeItem('userInfo');
        setToken(null);
        setUserToken(null);
    }

    useEffect(() => {
        AOS.init({
            duration: 600,
            easing: 'ease-in-out',
            once: true,
            mirror: false,
        });
        addPageRecord('home', window.location.href);
        const handleScroll = () => {
            const header = document.getElementById('header');
            const mainSection = document.getElementById('home');
            const headerBottom = header.getBoundingClientRect().bottom;
            const heroTop = mainSection.getBoundingClientRect().top;
            const mainBottom = mainSection.getBoundingClientRect().top;
            if (heroTop <= headerBottom) {
                header.classList.add('opaque');
            } else {
                header.classList.remove('opaque');
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.scrollTo(0, 0);
        document.body.style.overflow = 'scroll';
        window.scrollTo(0, 0);
        setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        const userInfo = JSON.parse(localStorage.getItem('userInfo'));
        setUserToken(userInfo?.token);

        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'AW-16595674367');

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function gtag_report_conversion(url) {
        var callback = function () {
            if (typeof url != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            send_to: 'AW-16595674367/pghOCMimi7cZEP_Jt-k9',
            value: 1.0,
            currency: 'INR',
            event_callback: callback,
        });
        return false;
    }

    return (
        <div className=" pb-0">
            <header
                id="header"
                className="header d-flex align-items-center fixed-top">
                <div className="container-fluid container-xl position-relative d-flex align-items-center">
                    <a
                        href="/"
                        className="logo d-flex align-items-center me-auto">
                        <img src={logo} alt="logo" />
                    </a>

                    <nav id="navmenu" className="navmenu">
                        <ul>
                            <li>
                                <a href="#home" className="">
                                    Home
                                </a>
                            </li>
                            <li>
                                <a href="#about">About</a>
                            </li>
                            <li>
                                <a href="#features">Features</a>
                            </li>
                            <li>
                                <a href="#pricing">Pricing</a>
                            </li>
                            <li>
                                <a href="#contact">Contact</a>
                            </li>
                        </ul>
                        <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
                    </nav>
                    {userToken ? (
                        <Link className="btn-getstarted" to="/login">
                            Get Started
                        </Link>
                    ) : (
                        <>
                            <Link className="btn-getstarted" to="/login">
                                Login
                            </Link>
                            <Link
                                className="btn-getstarted"
                                to="/register"
                                onClick={() => gtag_report_conversion()}>
                                Signup
                            </Link>
                        </>
                    )}
                </div>
            </header>

            <main className="main" id="app-main">
                <HeroSection />
                <FeaturedServicesSection />
                <AboutSection />
                <FeaturesSection />
                <PricingSection
                    currency={currency}
                    price={price}
                    changePrice={changePrice}
                />
                <ContactSection />
            </main>

            <Footer />
            <ScrollTop />
            {/* <Preloader /> */}
        </div>
    );
};

const HeroSection = () => (
    <section id="home" className="hero section">
        <div className="hero-bg">
            <img src={heroBgLight} alt="hero background" />
        </div>
        <div className="container text-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
                <h1 data-aos="fade-up">
                    Create your <span>Personalized AI Chat Agent</span> for your
                    website
                </h1>
                <p data-aos="fade-up" data-aos-delay="100">
                    Using the power of Chat GPT for any kind of Business, any
                    Language. All in few Clicks.
                    <br />
                </p>
                <img
                    src={infoBotGif}
                    className="img-fluid hero-img"
                    alt="Info Bot"
                    data-aos="zoom-out"
                    data-aos-delay="300"
                    style={{ border: '1px solid grey' }}
                />
                <br />
                <br />
                <div className="d-flex" data-aos="fade-up" data-aos-delay="200">
                    <Link to="/register" className="btn-get-started">
                        Get Started
                    </Link>
                </div>
            </div>
        </div>
    </section>
);

const FeaturedServicesSection = () => (
    <section id="featured-services" className="featured-services section">
        <div className="container">
            <div className="row gy-4">
                <ServiceItem
                    icon={faCalendar}
                    title="30 Days Free Trial"
                    description="Experience the vibe of having your own AI Chat Bot interacting with your prospects."
                    delay="100"
                />
                <ServiceItem
                    icon={faCreditCard}
                    title="No Credit Card Required"
                    description="Simply Signup and start creating your Personalized Chat Bot."
                    delay="200"
                />
                <ServiceItem
                    icon={faGlobe}
                    title="Support All Types of Website"
                    description="Place the Button to Access your Chat Bot on your website."
                    delay="300"
                />
            </div>
        </div>
    </section>
);

const ServiceItem = ({ icon, title, description, delay }) => (
    <div
        className="col-xl-4 col-lg-6"
        data-aos="fade-up"
        data-aos-delay={delay}>
        <div className="service-item d-flex">
            <div className="icon flex-shrink-0">
                <i>
                    <FontAwesomeIcon icon={icon} />
                </i>
            </div>
            <div>
                <h4 className="title">
                    <Link to="/register" className="stretched-link">
                        {title}
                    </Link>
                </h4>
                <p className="description">{description}</p>
            </div>
        </div>
    </div>
);

const AboutSection = () => (
    <section id="about" className="about section">
        <div className="container">
            <div className="row gy-4">
                <div
                    className="col-lg-6 content"
                    data-aos="fade-up"
                    data-aos-delay="100">
                    <p className="who-we-are">WHY INFOBOT</p>
                    <h3>Artificial Intelligence is the Present</h3>
                    <p className="fst-italic">
                        Your prospect is looking for information all the time.
                        We want you to give them all the information, without
                        waiting for a human being to address the enquiries.
                        InfoBOT will be your AI Customer Support Agent,
                        answering the questions like a human being. We use the
                        Power of ChatGPT to produce best results.
                    </p>
                    <ol>
                        <li>
                            {' '}
                            <span>Create an Account</span>
                        </li>
                        <li>
                            {' '}
                            <span>Add ChatGPT API Key</span>
                        </li>
                        <li>
                            {' '}
                            <span>
                                Upload Documents with all information about your
                                business
                            </span>
                        </li>
                        <li>
                            {' '}
                            <span>Add a Button on your website</span>
                        </li>
                    </ol>
                    <b>You are ready with your AI Chat Bot.</b>
                </div>
                <div
                    className="col-lg-6 about-images"
                    data-aos="fade-up"
                    data-aos-delay="200">
                    <div className="row gy-4">
                        <div className="col-lg-6">
                            <img
                                src={sec1}
                                className="img-fluid"
                                alt="screenshot 1"
                            />
                        </div>
                        <div className="col-lg-6">
                            <div className="row gy-4">
                                <div className="col-lg-12">
                                    <img
                                        src={sec2}
                                        className="img-fluid"
                                        alt="screenshot 2"
                                    />
                                </div>
                                <div className="col-lg-12">
                                    <img
                                        src={sec3}
                                        className="img-fluid"
                                        alt="screenshot 3"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const FeaturesSection = () => (
    <section id="features" className="features section">
        {/* Section Title */}
        <div className="container section-title" data-aos="fade-up">
            <h2>Features</h2>
            <p>We do just one thing and we do it Great !!!</p>
        </div>

        <div className="container">
            <div className="row justify-content-between">
                <div className="col-lg-5 d-flex align-items-center">
                    <ul
                        className="nav nav-tabs"
                        data-aos="fade-up"
                        data-aos-delay="100">
                        <li className="nav-item">
                            <a
                                className="nav-link active show"
                                data-bs-toggle="tab"
                                data-bs-target="#features-tab-1">
                                <i>
                                    <FontAwesomeIcon icon={faHandPointer} />
                                </i>

                                <div>
                                    <h4 className="d-none d-lg-block">
                                        Button on Your Website to Access Chat
                                        Bot
                                    </h4>
                                    <p>
                                        Name the button as per your creativity.
                                        Place it where you are confident will
                                        help you get most visitors.
                                    </p>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                data-bs-target="#features-tab-2">
                                <i>
                                    <FontAwesomeIcon icon={faDesktop} />
                                </i>
                                <div>
                                    <h4 className="d-none d-lg-block">
                                        Fully Customizable Chat Page
                                    </h4>
                                    <p>
                                        Change the color of header, body, chat
                                        messages, logo to match your business
                                        theme.
                                    </p>
                                </div>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-bs-toggle="tab"
                                data-bs-target="#features-tab-3">
                                <i>
                                    <FontAwesomeIcon icon={faUsers} />
                                </i>
                                <div>
                                    <h4 className="d-none d-lg-block">
                                        See what your prospect asked about you
                                    </h4>
                                    <p>
                                        History of all interactions by your
                                        users, accessible at one place.
                                    </p>
                                </div>
                            </a>
                        </li>
                    </ul>
                    {/* End Tab Nav */}
                </div>

                <div className="col-lg-6">
                    <div
                        className="tab-content"
                        data-aos="fade-up"
                        data-aos-delay="200">
                        <div
                            className="tab-pane fade active show"
                            id="features-tab-1">
                            <img src={tab1} alt="" className="img-fluid" />
                        </div>
                        {/* End Tab Content Item */}

                        <div className="tab-pane fade" id="features-tab-2">
                            <img src={tab2} alt="" className="img-fluid" />
                        </div>
                        {/* End Tab Content Item */}

                        <div className="tab-pane fade" id="features-tab-3">
                            <img src={tab3} alt="" className="img-fluid" />
                        </div>
                        {/* End Tab Content Item */}
                    </div>
                </div>
            </div>
        </div>
    </section>
);

const PricingSection = ({ currency, price, changePrice }) => (
    <section id="pricing" className="pricing section">
        <div className="container section-title" data-aos="fade-up">
            <h2>Pricing</h2>
            <p>Simplest Pricing you will ever get</p>
        </div>
        <div className="container">
            <div className="row gy-4">
                <div
                    className="col-lg-4"
                    data-aos="zoom-in"
                    data-aos-delay="100"></div>
                <div
                    className="col-lg-4"
                    data-aos="zoom-in"
                    data-aos-delay="100">
                    <div className="pricing-item">
                        <h3>Your Plan</h3>
                        <p className="description">
                            Chat GPT API Cost will be additional expense for you
                        </p>
                        <div className="row">
                            <div className="col-4">
                                <select
                                    className="form-control mt-2"
                                    style={{ WebkitAppearance: 'listbox' }}
                                    value={currency}
                                    onChange={changePrice}>
                                    <option value="USD">USD</option>
                                    <option value="INR">INR</option>
                                </select>
                            </div>
                            <div className="col-8">
                                <h4 id="price_value">
                                    <sup>{currency === 'USD' ? '$' : '₹'}</sup>
                                    {currency === 'USD' ? '10' : '500'}
                                    <span> / month</span>
                                </h4>
                            </div>
                        </div>
                        <Link to="/regsiter" className="cta-btn">
                            Start a free trial
                        </Link>
                        <p className="text-center small">
                            No credit card required
                        </p>
                    </div>
                </div>
                <div
                    className="col-lg-4"
                    data-aos="zoom-in"
                    data-aos-delay="100"></div>
            </div>
        </div>
    </section>
);

const ContactSection = () => (
    <section id="contact" className="contact section">
        <div className="container section-title" data-aos="fade-up">
            <h2>Contact</h2>
        </div>
        <div className="container" data-aos="fade-up" data-aos-delay="100">
            <div className="row gy-4">
                <div className="col-lg-4">
                    <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay="200">
                        <h3>Address</h3>
                        <p>Bangalore, India</p>
                    </div>
                </div>
                {/* End Info Item */}

                <div className="col-lg-4 col-md-6">
                    <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay="300">
                        <h3>Whatsapp</h3>
                        <p>
                            <a
                                href="https://wa.me/+916362176153"
                                target="_blank"
                                rel="noopener noreferrer">
                                +91 6362 176 153
                            </a>
                        </p>
                    </div>
                </div>
                {/* End Info Item */}

                <div className="col-lg-4 col-md-6">
                    <div
                        className="info-item d-flex flex-column justify-content-center align-items-center"
                        data-aos="fade-up"
                        data-aos-delay="400">
                        <h3>Email</h3>
                        <p>
                            <a href="mailto:infobot@idearise.co">
                                infobot@idearise.co
                            </a>
                        </p>
                    </div>
                </div>
                {/* End Info Item */}
            </div>
        </div>
    </section>
);

const Footer = () => (
    <footer id="footer" className="footer position-relative mb-2">
        <div className="container copyright text-center mt-4 mb-0 pb-0 border-none">
            <p className="border-none box-shadow-none">
                © <span>Copyright</span>{' '}
                <strong className="px-1 sitename">
                    Idea Rise Technologies
                </strong>
                <span>All Rights Reserved</span>
                <br />
                {/* <div className="d-flex align-items-center justify-content-cetner"> */}
                <Link target="_blank" to="/privacy_policy">
                    Privacy policy
                </Link>
                &nbsp;&nbsp;|&nbsp;&nbsp;
                <Link target="_blank" to="terms_of_use">
                    Terms of Use
                </Link>
                {/* </div> */}
            </p>
            <div className="credits">
                Designed by{' '}
                <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
        </div>
    </footer>
);

const ScrollTop = () => {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTopButton = document.getElementById('scroll-top');
            if (window.scrollY > 100) {
                scrollTopButton.classList.add('active');
            } else {
                scrollTopButton.classList.remove('active');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <a
            href="#"
            id="scroll-top"
            className="scroll-top d-flex align-items-center justify-content-center my-0"
            onClick={(e) => {
                e.preventDefault();
                scrollToTop();
            }}>
            <i className="my-0">
                <FontAwesomeIcon icon={faArrowUp} color="white" />
            </i>
        </a>
    );
};

const Preloader = () => <div id="preloader"></div>;

export default App;
