import React, { useState, useEffect, useContext } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Flex,
    Tr,
    Th,
    Td,
    IconButton,
    Box,
    ChakraProvider,
    Collapse,
    Badge,
    Heading,
    Text,
    Link,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { AuthContext } from './utils/AuthContext';
import Header from './Header';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import './Pagination.css';

export default function Clients() {
    const { getUsers, usersList, pages } = useContext(AuthContext);
    const [page, setPage] = useState(0);

    useEffect(() => {
        getUsers();
    }, []);

    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDateTime = format(date, 'PPpp'); // Format the date and time
        return formattedDateTime;
    };

    return (
        <ChakraProvider>
            {/* <Sidebar /> */}

            <Header />

            {/* Main Content */}
            <Box
                as="main"
                position="fixed"
                top="64px"
                // left={{ base: '30px', lg: '60px' }}
                left="0"
                right="0"
                bottom="0"
                overflow="auto"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': 'none', // IE and Edge
                    'scrollbar-width': 'none', // Firefox
                }}
                p={4}>
                <Heading size="md" mb={4}>
                    Clients
                </Heading>
                <Box
                    overflow="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none', // IE and Edge
                        'scrollbar-width': 'none', // Firefox
                    }}
                    minHeight={'600px'}
                    maxHeight="calc(100vh - 170px)"
                    // border="1px solid gray"
                    // borderRadius="md"
                >
                    <Table variant="simple" size="md" minWidth="800px">
                        <Thead
                            position="sticky"
                            top={0}
                            zIndex={1}
                            bg="white"
                            borderBottom="2px solid"
                            borderColor="gray.200">
                            <Tr>
                                <Th>User</Th>
                                <Th>Email</Th>
                                <Th>Organisation</Th>
                                <Th>Date Joined</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {usersList?.map((user, index) => {
                                const formattedDate = formatDateTime(
                                    user.created_at
                                );

                                return (
                                    <Tr>
                                        <Td>
                                            <Text fontWeight={'bold'}>
                                                {`${user.first_name} ${user.last_name}`}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text fontWeight={'bold'}>
                                                {user.email}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text fontWeight={'bold'}>
                                                {user.company.company_name}
                                            </Text>
                                        </Td>
                                        <Td>
                                            <Text fontWeight={'bold'}>
                                                {formattedDate}
                                            </Text>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </Table>
                </Box>
                <Box w="100%" mt={'2'}>
                    {pages > 1 && (
                        <Box size="sm">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                previousLabel="Previous"
                                forcePage={page}
                                onPageChange={(e) => {
                                    setPage(e.selected);
                                    localStorage.setItem(
                                        'pagination',
                                        e.selected
                                    );
                                    getUsers({
                                        page: e.selected + 1,
                                    });
                                }}
                                // pageRangeDisplayed={5}
                                pageCount={pages} // Replace with your total number of pages
                                marginPagesDisplayed={2}
                                containerClassName="pagination"
                                activeClassName="active"
                                // previousLinkClassName={page === 0 ? "disabled" : ""}
                                // nextLinkClassName={page === 9 ? "disabled" : ""}
                                breakClassName="break"
                                size="sm"
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </ChakraProvider>
    );
}
