import React, { useEffect, useState, useRef, useContext } from 'react';
import Sidebar from './Sidebar';
import { ChromePicker } from 'react-color';
import {
    Container,
    Flex,
    FormControl,
    FormLabel,
    VStack,
    Box,
    Center,
    Button,
    Input,
    Text,
    Table,
    Thead,
    Tbody,
    ChakraProvider,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuGroup,
    MenuDivider,
    useDisclosure,
    InputGroup,
    Spinner,
    Heading,
    Badge,
    Textarea,
    Tooltip,
    Image,
} from '@chakra-ui/react';
import Header from './Header';
import Screen from './Screen';
import { AuthContext } from './utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { BASE_URL } from './utils/config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import tabBtn from './app_step.png';

export default function Settings() {
    const [logo, setLogo] = useState(null);
    const [question, setQuestion] = useState('Hello How can i help you?');
    const [logoLink, setLogoLink] = useState('');
    const [headerColor, setHeaderColor] = useState('#ffffff');
    const [bodyColor, setBodyColor] = useState('#ffffff');
    const [inputMsgColor, setInputMsgColor] = useState('#ffffff');
    const [inputMsgBgColor, setInputMsgBgColor] = useState('#ffffff');
    const [responseMsgColor, setResponseMsgColor] = useState('#ffffff');
    const [responseMsgBgColor, setResponseMsgBgColor] = useState('#ffffff');
    // const [inputTextboxColor, setInputTextboxColor] = useState('#ffffff');
    const [inputTextboxBgColor, setInputTextboxBgColor] = useState('#ffffff');
    const [sendBtnColor, setSendBtnColor] = useState('#ffffff');
    const [sendBtnBgColor, setSendBtnBgColor] = useState('#ffffff');
    const [showPicker, setShowPicker] = useState(false);
    const [logoPreview, setLogoPreview] = useState(true);
    const headerRef = useRef();
    const [screenDisplay, setScreenDisplay] = useState(1);
    const [apiKey, setApiKey] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [defaultPrompt, setDefaultPrompt] = useState('');
    const {
        editCompanyInfo,
        getCompanyInfo,
        toastrMsg,
        toastrError,
        companyInfo,
        keySet,
        submitApiKey,
        setToastrError,
        setToastrMsg,
        addPageRecord,
    } = useContext(AuthContext);

    const values = {
        question,
        logo,
        logoLink,
        logoPreview,
        headerColor,
        bodyColor,
        inputMsgColor,
        inputMsgBgColor,
        // inputTextboxColor,
        inputTextboxBgColor,
        responseMsgColor,
        responseMsgBgColor,
        sendBtnColor,
        sendBtnBgColor,
        defaultPrompt,
    };
    useEffect(() => {
        addPageRecord('settings', window.location.href);
        getCompanyInfo();
    }, []);

    useEffect(() => {
        if (companyInfo?.logo) {
            setLogo(companyInfo?.logo);
            setLogoPreview(false);
        }
        setLogoLink(companyInfo?.logo_link || logoLink);

        setHeaderColor(companyInfo?.header_color || headerColor);
        setBodyColor(companyInfo?.body_color || bodyColor);
        setInputMsgBgColor(companyInfo?.input_msg_bg_color || inputMsgBgColor);
        setInputMsgColor(companyInfo?.input_msg_color || inputMsgColor);
        setResponseMsgBgColor(
            companyInfo?.response_msg_bg_color || responseMsgBgColor
        );
        setResponseMsgColor(
            companyInfo?.response_msg_color || responseMsgColor
        );
        setInputTextboxBgColor(
            companyInfo?.input_textbox_bg_color || inputTextboxBgColor
        );
        setSendBtnBgColor(companyInfo?.send_btn_bg_color || sendBtnBgColor);
        setSendBtnColor(companyInfo?.send_btn_color || sendBtnColor);
        setQuestion(companyInfo?.intro_q || question);
        setApiKey(companyInfo?.openai_key || apiKey);
        setDefaultPrompt(companyInfo?.default_prompt || defaultPrompt);
    }, [companyInfo]);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setIsLoading(false);
    }, [toastrMsg, toastrError, setToastrError, setToastrMsg]);

    const handleSubmit = () => {
        if (!apiKey) {
            toast.error('Please enter the openai api key.');
            return;
        }
        setIsLoading(true);
        const formData = new FormData();
        formData.append('intro_q', question);
        formData.append('logo', logo);
        formData.append('logo_link', logoLink);
        formData.append('header_color', headerColor);
        formData.append('body_color', bodyColor);
        formData.append('input_msg_color', inputMsgColor);
        formData.append('input_msg_bg_color', inputMsgBgColor);
        formData.append('response_msg_color', responseMsgColor);
        formData.append('response_msg_bg_color', responseMsgBgColor);
        formData.append('input_textbox_bg_color', inputTextboxBgColor);
        formData.append('send_btn_color', sendBtnColor);
        formData.append('send_btn_bg_color', sendBtnBgColor);
        formData.append('api_key', apiKey);
        formData.append('default_prompt', defaultPrompt);

        editCompanyInfo(formData);
    };

    const handleColorChange = (colorSetter) => (color) => {
        colorSetter(color.hex);
    };

    const ColorPicker = ({ color, setColor }) => {
        const { isOpen, onOpen, onClose } = useDisclosure();
        return (
            <Menu isOpen={isOpen} onClose={onClose}>
                <MenuButton
                    as={Button}
                    onClick={onOpen}
                    ml={2}
                    isDisabled={isLoading}>
                    Pick Color
                </MenuButton>
                <MenuList>
                    <Box p={2}>
                        <ChromePicker
                            color={color}
                            onChangeComplete={handleColorChange(setColor)}
                        />
                    </Box>
                </MenuList>
            </Menu>
        );
    };

    const handleApiSubmit = () => {
        const formData = new FormData();
        formData.append('api_key', apiKey);
        submitApiKey(formData);
    };

    return (
        <ChakraProvider>
            <ToastContainer />
            {/* < Sidebar /> */}
            <Header />
            {isLoading ? (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="rgba(255, 255, 255, 0.8)"
                    zIndex="9999">
                    <Spinner size="xl" />
                </Box>
            ) : (
                <Box
                    as="main"
                    position="fixed"
                    top="64px"
                    // left={{ base: '30px', lg: '60px' }}
                    left="0"
                    right="0"
                    bottom="0"
                    overflow="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none', // IE and Edge
                        'scrollbar-width': 'none', // Firefox
                    }}
                    p={4}>
                    <Heading size="md" my="5">
                        Steps to manage info bot
                    </Heading>

                    <Box h="100%" w="100%">
                        <Box h="100%" w="100%">
                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    ml="3"
                                    px="2">
                                    Step 1
                                </Badge>
                                <Box p={5} mb={5}>
                                    <FormControl id="api-key" mb={3}>
                                        {/* <Flex
                                                align={'center'}
                                                justify={'space-between'}> */}
                                        <FormLabel>
                                            Get ChatGPT API Key, paste here{' '}
                                            {'  '}
                                            <Tooltip
                                                placement="top"
                                                label={
                                                    <Box
                                                        as="span"
                                                        fontSize="12px">
                                                        Make sure to add credit
                                                        and set monthly limit
                                                        {/* <a
                                                        href="https://www.example.com"
                                                        target="_blank"
                                                        rel="noopener noreferrer">
                                                        here
                                                    </a> */}
                                                    </Box>
                                                }
                                                aria-label="Settings tooltip">
                                                <span
                                                    style={{
                                                        fontSize: '10px',
                                                    }}>
                                                    <i>(Instructions)</i>
                                                </span>
                                            </Tooltip>
                                            <a
                                                href="https://www.youtube.com/watch?v=wV8ogvB2cJU"
                                                target="_blank">
                                                <span
                                                    style={{
                                                        fontSize: '10px',
                                                    }}>
                                                    <i>(video)</i>
                                                </span>
                                            </a>
                                        </FormLabel>
                                        <Flex
                                            align={'center'}
                                            w={{
                                                base: '100%',
                                                sm: '100%',
                                                lg: '50%',
                                            }}
                                            justify={'center'}>
                                            <Input
                                                isDisabled={isLoading}
                                                flex="4"
                                                fontSize={'sm'}
                                                type="text"
                                                value={apiKey}
                                                onChange={(e) =>
                                                    setApiKey(e.target.value)
                                                }
                                            />
                                        </Flex>
                                        {/* </Flex> */}
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    ml="3"
                                    px="2">
                                    Step 2
                                </Badge>
                                <Box p={5} mb={5}>
                                    <FormControl id="api-key" mb={3}>
                                        {/* <Flex
                                                align={'center'}
                                                justify={'space-between'}> */}
                                        <FormLabel>
                                            Create Introduction message for your
                                            users
                                        </FormLabel>
                                        <Flex
                                            align={'center'}
                                            w={{
                                                base: '100%',
                                                sm: '100%',
                                                lg: '50%',
                                            }}
                                            justify={'center'}>
                                            <Textarea
                                                isDisabled={isLoading}
                                                whiteSpace="pre-wrap"
                                                size="sm"
                                                flex="4"
                                                type="text"
                                                value={question}
                                                onChange={(e) =>
                                                    setQuestion(e.target.value)
                                                }
                                            />
                                        </Flex>
                                        {/* </Flex> */}
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    ml="3"
                                    px="2">
                                    Step 3
                                </Badge>
                                <Box p={5} mb={5}>
                                    <FormControl id="api-key" mb={3}>
                                        {/* <Flex
                                                align={'center'}
                                                justify={'space-between'}> */}
                                        <FormLabel>
                                            Upload your company logo
                                        </FormLabel>

                                        <FormControl mb="1" id="logo">
                                            <Flex
                                                w={{
                                                    base: '100%',
                                                    sm: '100%',
                                                    lg: '50%',
                                                }}
                                                align="center"
                                                justify={'spcace-between'}>
                                                {/* <FormLabel
                                                        flex="2"
                                                        mb="0"
                                                        mr="2">
                                                        Logo
                                                    </FormLabel> */}
                                                <Flex
                                                    flex="2"
                                                    alignItems={'center'}>
                                                    <Input
                                                        isDisabled={isLoading}
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={(e) => {
                                                            setLogoPreview(
                                                                true
                                                            );
                                                            setLogo(
                                                                e.target
                                                                    .files[0]
                                                            );
                                                        }}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </FormControl>

                                        <FormControl
                                            mb="1"
                                            id="logo_link"
                                            display={'none'}>
                                            <Flex
                                                w="50%"
                                                align="center"
                                                justify={'spcace-between'}>
                                                <FormLabel
                                                    flex="2"
                                                    mb="0"
                                                    mr="2">
                                                    Logo Link
                                                </FormLabel>
                                                <Flex
                                                    flex="2"
                                                    alignItems={'center'}>
                                                    <Input
                                                        isDisabled={isLoading}
                                                        type="text"
                                                        value={logoLink}
                                                        onChange={(e) => {
                                                            setLogoLink(
                                                                e.target.value
                                                            );
                                                        }}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </FormControl>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    ml="3"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    px="2">
                                    Step 4
                                </Badge>
                                <Flex
                                    height="100%"
                                    width="100%"
                                    flexDirection={{
                                        base: 'column',
                                        // md: 'column',
                                        lg: 'row',
                                    }}>
                                    <Box
                                        height={{
                                            base: '50%',
                                            // md: '50%',
                                            lg: '100%',
                                        }}
                                        width={{
                                            base: '100%',
                                            // md: '100%',
                                            lg: '50%',
                                        }}>
                                        <Box
                                            h="100%"
                                            overflow={'scroll'}
                                            css={{
                                                '&::-webkit-scrollbar': {
                                                    display: 'none',
                                                },
                                                '-ms-overflow-style': 'none', // IE and Edge
                                                'scrollbar-width': 'none', // Firefox
                                            }}>
                                            {/* Chat UI Settings */}
                                            <Box p={5} mb={5}>
                                                <FormLabel
                                                    mb={3}
                                                    fontWeight={'bold'}>
                                                    Design your chat interface
                                                    and preview in the right
                                                    panel
                                                </FormLabel>
                                                <FormControl>
                                                    {[
                                                        {
                                                            id: 'header_color',
                                                            label: 'Header Color',
                                                            color: headerColor,
                                                            setColor:
                                                                setHeaderColor,
                                                        },
                                                        {
                                                            id: 'body_color',
                                                            label: 'Body Color',
                                                            color: bodyColor,
                                                            setColor:
                                                                setBodyColor,
                                                        },
                                                        {
                                                            id: 'input_msg_color',
                                                            label: 'Input Message Color',
                                                            color: inputMsgColor,
                                                            setColor:
                                                                setInputMsgColor,
                                                        },
                                                        {
                                                            id: 'input_msg_bg_color',
                                                            label: 'Input Message Background Color',
                                                            color: inputMsgBgColor,
                                                            setColor:
                                                                setInputMsgBgColor,
                                                        },
                                                        {
                                                            id: 'response_msg_color',
                                                            label: 'Response Message Color',
                                                            color: responseMsgColor,
                                                            setColor:
                                                                setResponseMsgColor,
                                                        },
                                                        {
                                                            id: 'response_msg_bg_color',
                                                            label: 'Response Message Background Color',
                                                            color: responseMsgBgColor,
                                                            setColor:
                                                                setResponseMsgBgColor,
                                                        },

                                                        {
                                                            id: 'input_textbox_bg_color',
                                                            label: 'Input Textbox Background Color',
                                                            color: inputTextboxBgColor,
                                                            setColor:
                                                                setInputTextboxBgColor,
                                                        },
                                                        {
                                                            id: 'send_btn_color',
                                                            label: 'Send Button Color',
                                                            color: sendBtnColor,
                                                            setColor:
                                                                setSendBtnColor,
                                                        },
                                                        {
                                                            id: 'send_btn_bg_color',
                                                            label: 'Send Button Background Color',
                                                            color: sendBtnBgColor,
                                                            setColor:
                                                                setSendBtnBgColor,
                                                        },
                                                    ].map(
                                                        ({
                                                            id,
                                                            label,
                                                            color,
                                                            setColor,
                                                        }) => (
                                                            <Flex
                                                                mb="2"
                                                                align="center"
                                                                justify={
                                                                    'space-between'
                                                                }>
                                                                <FormLabel
                                                                    mr="2"
                                                                    mb="0">
                                                                    {label}
                                                                </FormLabel>
                                                                <Flex
                                                                    alignItems="center"
                                                                    onClick={() =>
                                                                        setShowPicker(
                                                                            false
                                                                        )
                                                                    }>
                                                                    <Input
                                                                        isDisabled={
                                                                            isLoading
                                                                        }
                                                                        flex="2"
                                                                        type="text"
                                                                        value={
                                                                            color
                                                                        }
                                                                        readOnly
                                                                        bg="white"
                                                                    />
                                                                    <ColorPicker
                                                                        flex="1"
                                                                        fontSize={
                                                                            '10px'
                                                                        }
                                                                        color={
                                                                            color
                                                                        }
                                                                        setColor={
                                                                            setColor
                                                                        }
                                                                    />
                                                                </Flex>
                                                            </Flex>
                                                        )
                                                    )}
                                                </FormControl>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        bg="blue.100"
                                        height={{
                                            base: '50%',
                                            // md: '50%',
                                            lg: '100%',
                                        }}
                                        p={4}
                                        width={{
                                            base: '100%',
                                            // md: '100%',
                                            lg: '50%',
                                        }}>
                                        <Box height="5%">
                                            <Flex justify="space-around">
                                                <Button
                                                    isDisabled={isLoading}
                                                    size="sm"
                                                    onClick={() =>
                                                        setScreenDisplay(1)
                                                    }>
                                                    Desktop View
                                                </Button>
                                                <Button
                                                    isDisabled={isLoading}
                                                    size="sm"
                                                    onClick={() =>
                                                        setScreenDisplay(0)
                                                    }>
                                                    Mobile View
                                                </Button>
                                            </Flex>
                                        </Box>
                                        <Box height="95%" p={1}>
                                            <Screen
                                                display={screenDisplay}
                                                screenValues={values}
                                            />
                                        </Box>
                                    </Box>
                                </Flex>
                            </Box>

                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    mt="4"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    ml="3"
                                    px="2">
                                    Step 5
                                </Badge>
                                <Box p={5} mb={5}>
                                    <FormControl id="api-key" mb={3}>
                                        <FormLabel>
                                            Set your Default Prompt
                                        </FormLabel>
                                        <Flex
                                            w={{
                                                base: '100%',
                                                sm: '100%',
                                                lg: '50%',
                                            }}
                                            align={'center'}
                                            justify={'center'}>
                                            <Textarea
                                                isDisabled={isLoading}
                                                whiteSpace="pre-wrap"
                                                size="sm"
                                                flex="4"
                                                type="text"
                                                value={defaultPrompt}
                                                onChange={(e) =>
                                                    setDefaultPrompt(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Flex>
                                        {/* </Flex> */}
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    ml="3"
                                    px="2">
                                    Step 6
                                </Badge>
                                <Box p={5} mb={5}>
                                    <FormControl id="api-key" mb={3}>
                                        <Button
                                            isLoading={isLoading}
                                            colorScheme="gray"
                                            mt={3}
                                            onClick={() => {
                                                handleSubmit();
                                            }}>
                                            Click here to update Changes
                                        </Button>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    ml="3"
                                    px="2">
                                    Step 7
                                </Badge>
                                <Box p={5} mb={5}>
                                    <FormControl id="api-key" mb={3}>
                                        Upload information documents here{' '}
                                        <Link target="_blank" to={`/documents`}>
                                            <FontAwesomeIcon
                                                icon={faExternalLinkAlt}
                                                style={{
                                                    marginLeft: '0.5rem',
                                                }}
                                            />
                                        </Link>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    ml="3"
                                    px="2">
                                    Step 8
                                </Badge>
                                <Box p={5} mb={5}>
                                    <FormControl id="api-key" mb={3}>
                                        View your chat page here
                                        <Link
                                            target="_blank"
                                            to={`/chat/${
                                                JSON.parse(
                                                    localStorage.getItem(
                                                        'userInfo'
                                                    )
                                                )?.chat_code
                                            }`}>
                                            <FontAwesomeIcon
                                                icon={faExternalLinkAlt}
                                                style={{
                                                    marginLeft: '0.5rem',
                                                }}
                                            />
                                        </Link>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box>
                                <Badge
                                    borderRadius="5%"
                                    fontSize="xl"
                                    bg="blue.500"
                                    color="white"
                                    ml="3"
                                    px="2">
                                    Step 9
                                </Badge>
                                <Box p={5} mb={5}>
                                    <FormControl id="api-key" mb={3}>
                                        Create a button on your website and link
                                        to your chat page.
                                    </FormControl>
                                    <Flex>
                                        <Image
                                            src={tabBtn}
                                            w={{
                                                base: '100%',
                                                sm: '100%',
                                                lg: '50%',
                                            }}
                                            h={{
                                                base: '100%',
                                                sm: '100%',
                                                lg: '50%',
                                            }}></Image>
                                    </Flex>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            )}
        </ChakraProvider>
    );
}
