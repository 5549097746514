import { useState, useEffect, forwardRef, useContext } from 'react';
// import { Link } from 'react-router-dom';
import {
    Box,
    Flex,
    Text,
    Spacer,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Avatar,
    Link,
    Divider,
    Image,
    Icon,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './utils/AuthContext';
import AppLogo from './logo.png';
import { googleLogout } from '@react-oauth/google';
import { FaUser, FaBuilding, FaEnvelope } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header = forwardRef((props, ref) => {
    const { token, setToken, setHasGSigned, setIsRegistered } =
        useContext(AuthContext);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const navigate = useNavigate();
    function handleLogout() {
        localStorage.removeItem('userInfo');
        setToken(null);
        googleLogout();
        setHasGSigned(null);
        setIsRegistered(false);
        navigate('/login');
    }
    const sidebarWidth = useBreakpointValue({
        base: '30px',
        md: '30px',
        lg: '60px',
    });
    return (
        <Box
            zIndex="100"
            // w="100%"
            // h="100%"
            ref={ref}
            borderBottomWidth="1px"
            borderBottomColor="gray.200"
            boxShadow="0px 2px 4px rgba(0, 0, 0, 0.1)"
            as="header"
            position="fixed"
            top="0"
            // left={sidebarWidth}
            left={'0'}
            right="0"
            height="64px"
            padding="1.5rem"
            // bg="blue.500"
            color="white"
            bg="white"
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            {/* Left side: Logo */}
            <Box display="flex" alignItems="center">
                <Link>
                    <Image
                        ml="5"
                        alignSelf={'center'}
                        maxW="80%"
                        maxH="80%"
                        src={AppLogo}
                        color="black"
                    />
                </Link>
            </Box>

            {/* Center Spacer */}
            <Box flex="1" display="flex" justifyContent="center"></Box>

            {/* Right side: User Menu */}
            <Box>
                <Menu zIndex="sticky">
                    <MenuButton>
                        <Image
                            w={8}
                            h={8}
                            alt="User Avatar"
                            src={`https://ui-avatars.com/api/?name=${
                                JSON.parse(localStorage.getItem('userInfo'))
                                    ?.name
                            }&background=512ca8&color=ffffff&bold=true&rounded=true`}
                        />
                    </MenuButton>
                    <MenuList zIndex="sticky">
                        <Box p={2}>
                            <Flex
                                direction="row"
                                alignItems="center"
                                justifyContent="center">
                                <Box
                                    flex="1"
                                    mr={4}
                                    display="flex"
                                    justifyContent="center">
                                    <FaUser size="60px" color="black" />
                                </Box>

                                <Box flex="4" direction="column">
                                    <Text
                                        my="0"
                                        color="gray.900"
                                        fontWeight="bold"
                                        fontSize="lg">
                                        {userInfo?.name}
                                    </Text>
                                    <Flex alignItems="center">
                                        <Icon
                                            as={FaBuilding}
                                            boxSize={3}
                                            color="gray.600"
                                            mr={2}
                                        />
                                        <Text
                                            my="0"
                                            color="gray.600"
                                            fontSize="sm">
                                            {userInfo?.company}
                                        </Text>
                                    </Flex>
                                    <Flex alignItems="center">
                                        <Icon
                                            as={FaEnvelope}
                                            boxSize={3}
                                            color="gray.600"
                                            mr={2}
                                        />
                                        <Text
                                            my="0"
                                            color="gray.600"
                                            fontSize="sm">
                                            {userInfo?.email}
                                        </Text>
                                    </Flex>
                                </Box>

                                {/* <Box flex="4" direction="column">
                                    <Text
                                        color="black"
                                        fontWeight="bold"
                                        fontSize="lg"
                                        my="0">
                                        {userInfo?.name}
                                    </Text>
                                    <Text
                                        color="blue.400"
                                        fontWeight={'bold'}
                                        fontSize="sm"
                                        my="0">
                                        {userInfo?.email}
                                    </Text>
                                    <Text
                                        color="gray.600"
                                        fontSize="sm"
                                        my="0"
                                        fontWeight={'bold'}>
                                        {userInfo?.company}
                                    </Text>
                                </Box> */}
                            </Flex>
                        </Box>

                        <MenuItem
                            color="black"
                            onClick={() => navigate('/settings')}>
                            <Link
                                // href="/settings"
                                w="100%"
                                h="100%"
                                _hover={{ textDecoration: 'none' }}>
                                Settings
                            </Link>
                        </MenuItem>
                        <MenuItem
                            color="black"
                            onClick={() => navigate('/documents')}>
                            <Link
                                // href="/documents"
                                w="100%"
                                h="100%"
                                _hover={{ textDecoration: 'none' }}>
                                Documents
                            </Link>
                        </MenuItem>
                        <MenuItem
                            color="black"
                            onClick={() => navigate('/sessions')}>
                            <Link
                                w="100%"
                                h="100%"
                                // href="/sessions"
                                _hover={{ textDecoration: 'none' }}>
                                Chat History
                            </Link>
                        </MenuItem>
                        {JSON.parse(localStorage.getItem('userInfo'))
                            ?.superuser && (
                            <MenuItem
                                color="black"
                                onClick={() => navigate('/clients')}>
                                <Link
                                    w="100%"
                                    h="100%"
                                    _hover={{ textDecoration: 'none' }}>
                                    Clients
                                </Link>
                            </MenuItem>
                        )}
                        {JSON.parse(localStorage.getItem('userInfo'))
                            ?.superuser && (
                            <MenuItem
                                color="black"
                                onClick={() => navigate('/page_tracker')}>
                                <Link
                                    w="100%"
                                    h="100%"
                                    _hover={{ textDecoration: 'none' }}>
                                    Page Tracker
                                </Link>
                            </MenuItem>
                        )}
                        <MenuItem
                            color="black"
                            onClick={() => navigate('/change_password')}>
                            <Link
                                w="100%"
                                h="100%"
                                // href="/sessions"
                                _hover={{ textDecoration: 'none' }}>
                                Change Password
                            </Link>
                        </MenuItem>

                        <MenuItem color="black" onClick={handleLogout}>
                            Logout
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Box>
    );
});

export default Header;
