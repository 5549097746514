import MessageBoxChat from './components/MessageBox';
import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    Box,
    Button,
    Flex,
    Input,
    Text,
    VStack,
    useBreakpointValue,
} from '@chakra-ui/react';

import { ChakraProvider } from '@chakra-ui/react';
import './App.css';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from './utils/AuthContext';
import HeaderChat from './HeaderChat';
import { BASE_URL } from './utils/config';

export default function Chat(props) {
    const [sessionId, setSessionId] = useState(null);
    // const [chatHistory, setChatHistory] = useState([]);
    const [inputCode, setInputCode] = useState('');
    const [loading, setLoading] = useState(false);

    const chatBoxRef = useRef();

    const navigate = useNavigate();

    const {
        checkCompany,
        company,
        publicInfo,
        openaiChat,
        chatHistory,
        setChatHistory,
        getChats,
    } = useContext(AuthContext);

    const { companyParam } = useParams();

    useEffect(() => {
        checkCompany(companyParam);
        const setVh = () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVh();
        window.addEventListener('resize', setVh);
        return () => window.removeEventListener('resize', setVh);
    }, []);

    useEffect(() => {
        if (company === false) {
            navigate('/page_does_not_exist');
        } else if (company === true) {
            getChats();
            setSessionId(generateRandomString(52));
        }
    }, [company]);

    const headerRef = useRef();

    function generateRandomString(length) {
        const characters =
            'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
        }

        return result;
    }

    const fetchData = () => {
        setLoading(true);

        setChatHistory((prev) => [
            ...prev,
            { message: inputCode, role: 'user' },
        ]);

        // openaiChat(inputCode, companyParam, sessionId);

        const body = {
            prompt: inputCode,
            companyName: companyParam,
            // sessionKey: localStorage.getItem('sessionId'),
        };
        setInputCode('');

        // const sessionCookie = sessionStorage.getItem('session_id');

        fetch(`${BASE_URL}/api/open_ai_chain/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .then((data) => {
                const content = data.response;

                setChatHistory((prev) => [
                    ...prev,
                    { message: content, role: 'system' },
                ]);
                // const sessionId = data.session_id;
                // if (sessionId) {
                //     sessionStorage.setItem('session_id', sessionId);
                // }
                setLoading(false);
            })
            .catch((err) => {});
    };

    useEffect(() => {
        if (chatHistory && chatHistory.length === 0) {
            return;
        }
        if (chatBoxRef.current) {
            scrollToBottom();
        }
    }, [chatHistory, chatBoxRef]);

    const handleChange = (event) => {
        setInputCode(event.target.value);
    };

    useEffect(() => {}, [publicInfo]);

    function scrollToBottom() {
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }

    return (
        publicInfo && (
            <ChakraProvider>
                <Flex
                    direction="column"
                    h="calc(var(--vh, 1vh) * 100)"
                    // h="100vh"
                    // overflowY={'hidden'}
                    w="100%"
                    bg={publicInfo?.body_color}>
                    <Flex
                        w="100%"
                        position="sticky"
                        top="0"
                        zIndex="1000"
                        bg={publicInfo?.header_color}>
                        <HeaderChat
                            headerValues={{
                                headerColor: publicInfo?.header_color,
                                logo: publicInfo?.logo,
                                logoLink: publicInfo?.logo_link,
                            }}
                        />
                    </Flex>
                    <VStack
                        ref={chatBoxRef}
                        flex="1"
                        overflowY="auto"
                        spacing={4}
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '-ms-overflow-style': 'none', // IE and Edge
                            'scrollbar-width': 'none', // Firefox
                        }}
                        my={4}
                        mx={{
                            base: 5,
                            lg: '10rem',
                        }}
                        p={4}
                        // mt="80px"
                        rounded="md">
                        {publicInfo?.intro_q && (
                            <Box
                                alignSelf="flex-start"
                                bg={publicInfo?.response_msg_bg_color}
                                p={2}
                                maxW="90%"
                                rounded="md"
                                shadow="md">
                                <Text
                                    mb="0"
                                    pb="0"
                                    whiteSpace="pre-wrap"
                                    color={publicInfo?.response_msg_color}>
                                    {publicInfo?.intro_q}
                                </Text>
                            </Box>
                        )}

                        {chatHistory &&
                            chatHistory.map((msg, index) => {
                                if (msg.role) {
                                    if (msg.role === 'user') {
                                        return (
                                            <Box
                                                key={`${index}user`}
                                                alignSelf="flex-end"
                                                bg={
                                                    publicInfo?.input_msg_bg_color
                                                }
                                                p={2}
                                                rounded="md"
                                                maxW="75%"
                                                shadow="md">
                                                <Text
                                                    mb="0"
                                                    pb="0"
                                                    whiteSpace="pre-wrap"
                                                    color={
                                                        publicInfo?.input_msg_color
                                                    }>
                                                    {msg.message}
                                                </Text>
                                            </Box>
                                        );
                                    } else if (msg.role === 'system') {
                                        return (
                                            <Box
                                                key={`${index}system`}
                                                alignSelf="flex-start"
                                                bg={
                                                    publicInfo?.response_msg_bg_color
                                                }
                                                p={2}
                                                maxW="90%"
                                                rounded="md"
                                                shadow="md">
                                                <Text
                                                    mb="0"
                                                    pb="0"
                                                    whiteSpace="pre-wrap"
                                                    color={
                                                        publicInfo?.response_msg_color
                                                    }>
                                                    {msg.message}
                                                </Text>
                                            </Box>
                                        );
                                    }
                                } else if (msg.prompt && msg.response) {
                                    return (
                                        <React.Fragment>
                                            <Box
                                                key={`${index}user`}
                                                alignSelf="flex-end"
                                                bg={
                                                    publicInfo?.input_msg_bg_color
                                                }
                                                p={2}
                                                rounded="md"
                                                maxW="75%"
                                                shadow="md">
                                                <Text
                                                    mb="0"
                                                    pb="0"
                                                    whiteSpace="pre-wrap"
                                                    color={
                                                        publicInfo?.input_msg_color
                                                    }>
                                                    {msg.prompt}
                                                </Text>
                                            </Box>
                                            <Box
                                                key={`${index}system`}
                                                alignSelf="flex-start"
                                                bg={
                                                    publicInfo?.response_msg_bg_color
                                                }
                                                p={2}
                                                maxW="90%"
                                                rounded="md"
                                                shadow="md">
                                                <Text
                                                    mb="0"
                                                    pb="0"
                                                    whiteSpace="pre-wrap"
                                                    color={
                                                        publicInfo?.response_msg_color
                                                    }>
                                                    {msg.response}
                                                </Text>
                                            </Box>
                                        </React.Fragment>
                                    );
                                }
                            })}
                    </VStack>
                    <Flex
                        my={5}
                        p={1}
                        mx={{
                            base: 5,
                            lg: '10rem',
                        }}
                        borderRadius={'50px'}
                        bg={publicInfo?.input_textbox_bg_color}>
                        <Input
                            ms={1}
                            flex="1"
                            _focus={{ border: 'none', boxShadow: 'none' }}
                            boxShadow={'none'}
                            border="none"
                            value={inputCode}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();

                                    fetchData();
                                }
                            }}
                            onChange={(e) => {
                                handleChange(e);
                            }}
                            placeholder="Type a message"
                        />
                        <Button
                            // bg={publicInfo?.send_btn_bg_color}
                            // bg="white"
                            color={publicInfo?.send_btn_color}
                            _hover={{
                                boxShadow:
                                    '0px 21px 27px -10px rgba(96, 60, 255, 0.48) !important',
                                bg: `${publicInfo?.send_btn_bg_color}`,
                                _disabled: {
                                    bg: 'lightgray',
                                },
                            }}
                            w={5}
                            isLoading={loading ? true : false}
                            borderRadius={'50%'}
                            onClick={() => {
                                fetchData();
                            }}>
                            ↑
                        </Button>
                    </Flex>
                </Flex>
            </ChakraProvider>
        )
    );
}
