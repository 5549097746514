import React from 'react';
import {
    ChakraProvider,
    Center,
    Text,
    Box,
    Flex,
    Heading,
    Image,
} from '@chakra-ui/react';
import PageError from './page_404.png';

export default function PageNotFound() {
    return (
        <ChakraProvider>
            <Flex
                my="auto"
                align="center"
                justify={'center'}
                h="100vh"
                w="100%"
                bg="#ffffbe">
                <Image
                    maxW={{ base: '100%', lg: '50%' }}
                    src={PageError}></Image>
            </Flex>
        </ChakraProvider>
    );
}
