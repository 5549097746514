import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Select,
    Table,
    Thead,
    Tbody,
    Flex,
    Tr,
    Th,
    Td,
    Text,
    IconButton,
    Box,
    Container,
    ChakraProvider,
    Divider,
    Icon,
    Collapse,
    Badge,
    Heading,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { TiPencil, TiTrash } from 'react-icons/ti';
import { AuthContext } from './utils/AuthContext';
import React, { useEffect, useContext, useState, useRef } from 'react';
// import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar';
import Header from './Header';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import './Pagination.css';

const Sessions = () => {
    const { chatSessions, sessions, pages, addPageRecord } =
        useContext(AuthContext);

    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('1');
    const [designation, setDesignation] = useState('');
    const [department, setDepartment] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [mode, setMode] = useState('');
    const [fadeEmail, setFadeEmail] = useState(false);
    const [showTable, setShowTable] = useState(false);
    // const hotTableRef = useRef(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [headerHeight, setHeaderHeight] = useState(null);
    const [clientBtnHeight, setClientBtnHeight] = useState(null);
    const [totalHeight, setTotalHeight] = useState(null);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const [page, setPage] = useState(0);

    const navigate = useNavigate();

    const handleToggle = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const headerRef = useRef();
    const addClientRef = useRef();

    useEffect(() => {
        addPageRecord('chat_history', window.location.href);
        chatSessions();
    }, []);

    const onSidebarClose = () => setIsSidebarOpen(false);
    const onSidebarOpen = () => setIsSidebarOpen(true);

    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDateTime = format(date, 'PPpp'); // Format the date and time
        return formattedDateTime;
    };

    // const sidebarWidth = useBreakpointValue({
    //     base: '30px',
    //     md: '30px',
    //     lg: '60px',
    // });

    return (
        <ChakraProvider>
            {/* <Sidebar /> */}

            <Header />

            {/* Main Content */}
            <Box
                as="main"
                position="fixed"
                top="64px"
                // left={{ base: '30px', lg: '60px' }}
                left="0"
                right="0"
                bottom="0"
                overflow="auto"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': 'none', // IE and Edge
                    'scrollbar-width': 'none', // Firefox
                }}
                p={4}>
                <Heading size="md" mb={4}>
                    Chat History
                </Heading>
                <Box
                    overflow="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none', // IE and Edge
                        'scrollbar-width': 'none', // Firefox
                    }}
                    minHeight={'600px'}
                    maxHeight="calc(100vh - 170px)"
                    // border="1px solid gray"
                    // borderRadius="md"
                >
                    <Table variant="simple" size="md" minWidth="800px">
                        <Thead
                            position="sticky"
                            top={0}
                            zIndex={1}
                            bg="white"
                            borderBottom="2px solid"
                            borderColor="gray.200">
                            <Tr>
                                <Th>Session</Th>
                                <Th>View</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {sessions?.map((session, index) => {
                                const details = session.chat_history;

                                const formattedDate = formatDateTime(
                                    session.first_prompt_time
                                );

                                return (
                                    <React.Fragment key={index}>
                                        <Tr>
                                            <Td>
                                                <Flex align="center" my={4}>
                                                    <Badge
                                                        w={'15rem'}
                                                        colorScheme="blue"
                                                        px={4}
                                                        py={2}>
                                                        {formatDateTime(
                                                            session.first_prompt_time
                                                        )}
                                                    </Badge>
                                                    <Box
                                                        w={'2rem'}
                                                        height="1px"
                                                        bg="gray.200"
                                                    />
                                                    <Badge
                                                        w={'15rem'}
                                                        colorScheme="green"
                                                        px={4}
                                                        py={2}>
                                                        {session.first_prompt.slice(
                                                            0,
                                                            25
                                                        )}
                                                        ...
                                                    </Badge>
                                                </Flex>
                                                <Collapse
                                                    in={expandedIndex === index}
                                                    animateOpacity>
                                                    <Box p={4} bg="gray.100">
                                                        {/* <Box
                                                                key={`${index}detail`}
                                                                mb={2}>
                                                                <strong>
                                                                    Chat
                                                                    Prompt:
                                                                </strong>{' '}
                                                                {
                                                                    session.prompt
                                                                }
                                                                <br />
                                                                <strong>
                                                                    Response:
                                                                </strong>{' '}
                                                                {
                                                                    session.response
                                                                }
                                                            </Box> */}
                                                        {details.map(
                                                            (
                                                                detail,
                                                                detailIndex
                                                            ) => (
                                                                <Box
                                                                    key={
                                                                        detailIndex
                                                                    }
                                                                    mb={2}>
                                                                    <strong>
                                                                        Chat
                                                                        Prompt:
                                                                    </strong>{' '}
                                                                    {
                                                                        detail.prompt
                                                                    }
                                                                    <br />
                                                                    <strong>
                                                                        Response:
                                                                    </strong>{' '}
                                                                    {
                                                                        detail.response
                                                                    }
                                                                </Box>
                                                            )
                                                        )}
                                                    </Box>
                                                </Collapse>
                                            </Td>
                                            <Td>
                                                <IconButton
                                                    size="sm"
                                                    onClick={() =>
                                                        handleToggle(index)
                                                    }
                                                    icon={
                                                        expandedIndex ===
                                                        index ? (
                                                            <ChevronUpIcon />
                                                        ) : (
                                                            <ChevronDownIcon />
                                                        )
                                                    }
                                                    aria-label={
                                                        expandedIndex === index
                                                            ? 'Collapse'
                                                            : 'Expand'
                                                    }
                                                />
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                );
                            })}
                        </Tbody>
                    </Table>
                </Box>
                <Box w="100%" mt={'2'}>
                    {pages > 1 && (
                        <Box size="sm">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                previousLabel="Previous"
                                forcePage={page}
                                onPageChange={(e) => {
                                    setPage(e.selected);
                                    localStorage.setItem(
                                        'pagination',
                                        e.selected
                                    );
                                    chatSessions({
                                        page: e.selected + 1,
                                    });
                                }}
                                // pageRangeDisplayed={5}
                                pageCount={pages} // Replace with your total number of pages
                                marginPagesDisplayed={2}
                                containerClassName="pagination"
                                activeClassName="active"
                                // previousLinkClassName={page === 0 ? "disabled" : ""}
                                // nextLinkClassName={page === 9 ? "disabled" : ""}
                                breakClassName="break"
                                size="sm"
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </ChakraProvider>
    );
};

export default Sessions;
