import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from './config';
import Cookies from 'js-cookie';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [toastrMsg, setToastrMsg] = useState(null);
    const [toastrError, setToastrError] = useState(null);
    const [token, setToken] = useState(null);
    const [isRegistered, setIsRegistered] = useState(false);
    const [contactsList, setContactsList] = useState(null);
    const [usersList, setUsersList] = useState(null);
    const [messages, setMessages] = useState(null);
    const [cashFlow, setCashFlow] = useState(null);
    const [accountData, setAccountData] = useState(null);
    const [userData, setUserData] = useState(null);
    const [externalData, setExternalData] = useState(null);
    const [loggedOut, setLoggedOut] = useState(false);
    const [bulkDataMsg, setBulkDataMsg] = useState(null);
    const [bulkDataError, setBulkDataError] = useState(null);
    const [vendorAccounts, setVendorAccounts] = useState(null);
    const [toastrOtpMsg, setToastrOtpMsg] = useState(null);
    const [toastrOtpError, setToastrOtpError] = useState(null);
    const [toastrEmailMsg, setToastrEmailMsg] = useState(null);
    const [toastrEmailError, setToastrEmailError] = useState(null);
    const [hasGSigned, setHasGSigned] = useState(null);
    const [googleRegistered, setGoogleRegistered] = useState(null);
    const [companyData, setCompanyData] = useState(null);
    const [companyLocations, setCompanyLocations] = useState(null);
    const [taskTypes, setTaskTypes] = useState(null);
    const [taskList, setTaskList] = useState(null);
    const [cashFilterList, setCashFilterList] = useState(null);
    const [pages, setPages] = useState(null);
    const [attendanceList, setAttendanceList] = useState(null);
    const [profileData, setProfileData] = useState(null);
    const [userType, setUserType] = useState(null);
    const [docs, setDocs] = useState(null);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [company, setCompany] = useState(null);
    const [chatHistory, setChatHistory] = useState([]);
    const [sessions, setSessions] = useState(null);
    const [keySet, setKeySet] = useState(null);
    const [publicInfo, setPublicInfo] = useState(null);
    const [otpSent, setOtpSent] = useState(false);
    const [cred, setCred] = useState(null);
    const [pageVisits, setPageVisits] = useState(null);

    const register = (email, name, company, gSign = false) => {
        axios
            .post(`${BASE_URL}/api/register/`, { email, name, company, gSign })
            .then((res) => {
                setLoggedOut(false);
                let incomingData = res.data;

                if (incomingData.success) {
                    setToastrMsg(incomingData.msg);
                    if (gSign) {
                        localStorage.setItem(
                            'userInfo',
                            JSON.stringify(incomingData)
                        );
                        setGoogleRegistered(true);
                    } else {
                        setIsRegistered(true);
                    }
                    setToastrError(null);
                } else if (!incomingData.success) {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const sendOtp = (email) => {
        axios
            .post(`${BASE_URL}/api/send-otp/`, { email })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setOtpSent(true);
                    setToastrEmailMsg(incomingData.msg);
                    setToastrEmailError(null);
                } else if (!incomingData.success) {
                    setToastrEmailError(incomingData.msg);
                    setToastrEmailMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const verifyOtp = (email, otp) => {
        axios
            .post(`${BASE_URL}/api/verify-otp/`, { email, otp })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setToastrOtpMsg(incomingData.msg);
                    setToastrOtpError(null);
                } else if (!incomingData.success) {
                    setToastrOtpError(incomingData.msg);
                    setToastrOtpMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const setPassword = (email, password) => {
        axios
            .post(`${BASE_URL}/api/set-password/`, { email, password })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else if (!incomingData.success) {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const resetPassword = (currentPassword, password) => {
        axios
            .post(
                `${BASE_URL}/api/reset-password/`,
                {
                    currentPassword,
                    password,
                },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    // const userInfo = JSON.parse(
                    //     localStorage.getItem('userInfo')
                    // );
                    // userInfo.cred = true;
                    // localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    setCred('yes');
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else if (!incomingData.success) {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const checkUserPassword = () => {
        axios
            .get(`${BASE_URL}/api/check_user_password/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    if (incomingData.cred) {
                        setCred('yes');
                    } else {
                        setCred('no');
                    }
                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                } else if (!incomingData.success) {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An error occured. Please try again');
                setToastrMsg(null);
            });
    };

    const checkEmail = (email) => {
        axios
            .post(`${BASE_URL}/api/check-email/`, { email })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    localStorage.setItem(
                        'userInfo',
                        JSON.stringify(incomingData)
                    );
                    setToken(incomingData.token);

                    setToastrMsg(incomingData.msg);
                    setToastrError(null);
                    setHasGSigned('yes');
                } else if (!incomingData.success) {
                    setHasGSigned('no');
                    // setToastrError(incomingData.msg);
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setHasGSigned('no');
                // setToastrError("An error occured");
                setToastrMsg(null);
            });
    };

    const checkGRegister = (email) => {
        axios
            .post(`${BASE_URL}/api/gregister_check/`, { email })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    localStorage.setItem(
                        'userInfo',
                        JSON.stringify(incomingData)
                    );
                    setToastrMsg(null);
                    setToastrError(null);
                    setHasGSigned('yes');
                } else if (!incomingData.success) {
                    setHasGSigned('no');
                    // setToastrError(incomingData.msg);
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setHasGSigned('no');
                setToastrError('An error occured');
                setToastrMsg(null);
            });
    };

    async function setLocalStorage(data) {
        localStorage.setItem('userInfo', JSON.stringify(data));
    }

    const login = async (username, password) => {
        try {
            const response = await axios.post(`${BASE_URL}/api/login/`, {
                username,
                password,
            });
            const incomingData = response.data;
            if (incomingData.success) {
                setLoggedOut(false);
                // Update state with authentication details
                await setLocalStorage(incomingData);
                setToken(incomingData.token);
                setUserType(incomingData.type);
                setToastrMsg(incomingData.msg);
                setToastrError(null);
            } else if (!incomingData.success) {
                // Handle unsuccessful login
                setToastrError(incomingData.msg);
                setToastrMsg(null);
            }
        } catch (error) {
            setToastrError('Login Failed');
            setToastrMsg(null);
        }
    };

    const getDocuments = () => {
        axios
            .get(`${BASE_URL}/api/documents/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                setDocs(res.data.files);
            })
            .catch((err) => {
                console.warn(`documents error ${err}`);
            });
    };

    const addDocuments = (formData) => {
        axios
            .post(`${BASE_URL}/api/add_documents/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setToastrMsg(res.data.msg);
                    setToastrError(null);
                    getDocuments();
                } else {
                    setToastrError(res.data.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An Error occured');
                setToastrMsg(null);
            });
    };

    const deleteDocument = (id, docName) => {
        axios
            .post(
                `${BASE_URL}/api/delete_document/`,
                { id, docName },
                {
                    headers: {
                        Authorization: `Token ${
                            JSON.parse(localStorage.getItem('userInfo'))?.token
                        }`,
                    },
                }
            )
            .then((res) => {
                setToastrMsg(res.data.msg);
                setToastrError(null);
                getDocuments();
            })
            .catch((err) => {
                setToastrError('An Error occured');
                setToastrMsg(null);
            });
    };

    const checkCompany = (companyName) => {
        axios
            .get(`${BASE_URL}/api/check_company/${companyName}`)
            .then((res) => {
                const response = res.data;
                if (response.success) {
                    setCompany(true);
                    setPublicInfo(res.data.response);
                } else {
                    setCompany(false);
                }
            })
            .catch((err) => {
                console.warn(`documents error ${err}`);
            });
    };

    const getCompanyInfo = () => {
        axios
            .get(`${BASE_URL}/api/get_company_info/`, {
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setCompanyInfo(res.data.response);
                    setKeySet('set');
                }
                if (!incomingData.success) {
                    if (incomingData.key_error) {
                        setKeySet('unset');
                    }
                }
            })
            .catch((err) => {});
    };

    const editCompanyInfo = (formData) => {
        axios
            .post(`${BASE_URL}/api/edit_company_info/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setToastrMsg(res.data.msg);
                    getCompanyInfo();
                    setToastrError(null);
                } else {
                    setToastrError(incomingData.msg);
                    setToastrMsg(null);
                }
            })
            .catch((err) => {
                setToastrError('An Error occured');
                setToastrMsg(null);
            });
    };

    const submitApiKey = (formData) => {
        axios
            .post(`${BASE_URL}/api/submit_api_key/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setToastrError(null);
                    setToastrMsg(incomingData.msg);
                    getCompanyInfo();
                } else if (!incomingData.success) {
                    setToastrMsg(null);
                    setToastrError(incomingData.msg);
                }
            });
    };

    const chatSessions = ({ page = null } = {}) => {
        let params = {};
        if (page) {
            params['page'] = page;
        }
        axios
            .get(`${BASE_URL}/api/chat_sessions/`, {
                params: params,
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                setSessions(res.data.response);
                setPages(res.data.num_pages);
            })
            .catch((err) => {
                setToastrError('An error occured');
            });
    };

    const getChats = () => {
        axios
            .get(`${BASE_URL}/api/get_chats/`)
            .then((res) => {
                const incomingData = res.data;
                if (incomingData.success) {
                    setChatHistory(incomingData.response);
                }
            })
            .catch((err) => {});
    };

    const openaiChat = (prompt, companyName, sessionKey) => {
        axios
            .post(
                `${BASE_URL}/api/open_ai_chain/`,
                { prompt, companyName, sessionKey },
                {
                    headers: {
                        'X-CSRFToken': Cookies.get('csrftoken'), // CSRF token from cookies
                    },
                    withCredentials: true,
                }
            )
            .then((res) => {
                const incomingData = res.data;
                setChatHistory((prev) => [
                    ...prev,
                    { message: incomingData, role: 'system' },
                ]);
                // chatResponses(companyName);
            })
            .catch((err) => {
                setToastrError('An error occured');
            });
    };

    const getUsers = ({ page = null } = {}) => {
        let params = {};
        if (page) {
            params['page'] = page;
        }
        axios
            .get(`${BASE_URL}/api/get_users/`, {
                params: params,
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                const incomingData = res.data;
                if (incomingData?.success) {
                    setUsersList(incomingData?.response);
                    setPages(incomingData?.num_pages);
                }
            })
            .catch((err) => {});
    };

    const getPagesRecord = ({
        pageName = null,
        reset = null,
        page = null,
    } = {}) => {
        let params = {};
        if (reset) {
            params = {
                reset: '1',
            };
        } else {
            params = {
                pageName,
            };
        }
        if (page) {
            params['page'] = page;
        }
        axios
            .get(`${BASE_URL}/api/page_visits/`, {
                params: params,
                headers: {
                    Authorization: `Token ${
                        JSON.parse(localStorage.getItem('userInfo'))?.token
                    }`,
                },
            })
            .then((res) => {
                let incomingData = res.data;
                if (incomingData.success) {
                    setPageVisits(incomingData.response);
                    setPages(incomingData.num_pages);
                }
            })
            .catch((err) => {});
    };

    const addPageRecord = (pageName, url) => {
        axios
            .post(`${BASE_URL}/api/add_page_record/`, {
                pageName,
                url,
            })
            .then((res) => {})
            .catch({});
    };

    async function removeLocalStorage() {
        localStorage.removeItem('userInfo');
        setToken(null);
        setUserType(null);
        setLoggedOut(true);
    }

    const logout = async () => {
        localStorage.removeItem('userInfo');
        await removeLocalStorage();
    };

    return (
        <AuthContext.Provider
            value={{
                userInfo,
                contactsList,
                messages,
                cashFlow,
                userData,
                accountData,
                toastrMsg,
                toastrError,
                token,
                isRegistered,
                usersList,
                loggedOut,
                bulkDataMsg,
                bulkDataError,
                vendorAccounts,
                toastrEmailMsg,
                toastrEmailError,
                toastrOtpMsg,
                toastrOtpError,
                hasGSigned,
                googleRegistered,
                companyData,
                taskTypes,
                taskList,
                cashFilterList,
                externalData,
                pages,
                attendanceList,
                profileData,
                companyLocations,
                userType,
                docs,
                company,
                chatHistory,
                sessions,
                companyInfo,
                keySet,
                publicInfo,
                otpSent,
                cred,
                pageVisits,
                addPageRecord,
                getPagesRecord,
                setIsRegistered,
                checkUserPassword,
                getChats,
                setChatHistory,
                getUsers,
                setHasGSigned,
                setToken,
                submitApiKey,
                getCompanyInfo,
                editCompanyInfo,
                chatSessions,
                openaiChat,
                checkCompany,
                deleteDocument,
                addDocuments,
                getDocuments,
                checkGRegister,
                setCompanyLocations,
                checkEmail,
                resetPassword,
                setPassword,
                setToastrEmailMsg,
                setToastrEmailError,
                setToastrOtpMsg,
                setToastrOtpError,
                sendOtp,
                setBulkDataMsg,
                setBulkDataError,
                setToastrMsg,
                setToastrError,
                verifyOtp,
                register,
                login,
                logout,
            }}>
            {children}
        </AuthContext.Provider>
    );
};
