import React, { useRef, useState, useEffect } from 'react';
import { Box, Image, Center, ChakraProvider } from '@chakra-ui/react';
import laptop from './img/laptop_screen.png';
import mobile from './img/mobile_screen.png';
import ScreenApp from './ScreenApp';

export default function Screen(props) {
    const { display, screenValues } = props;

    const boxRef = useRef();
    const mobBoxRef = useRef();

    const [boxHeight, setBoxHeight] = useState(boxRef?.current?.offsetHeight);
    const [mobBoxHeight, setMobBoxHeight] = useState(
        mobBoxRef?.current?.offsetHeight
    );

    const updateHeight = () => {
        if (boxRef.current) {
            setBoxHeight(boxRef.current.offsetHeight);
        }
        if (mobBoxRef.current) {
            setMobBoxHeight(mobBoxRef.current.offsetHeight);
        }
    };

    return (
        // <ChakraProvider>
        <Center height="100%" bg="gray.100">
            <Box position="relative" h="100%" width="100%" alignSelf={'center'}>
                {(display === 1 && (
                    <>
                        <Image
                            src={laptop}
                            alt="Laptop Outline"
                            width="100%"
                            height="75%"
                        />
                        <Box
                            ref={boxRef}
                            position="absolute"
                            top="16%"
                            left="16%"
                            width="68%"
                            height="66%"
                            bg="blue.500">
                            <ScreenApp screenValues={screenValues} />
                        </Box>
                    </>
                )) ||
                    (display === 0 && (
                        <>
                            <Box position="relative" h="100%" width="100%">
                                <Image
                                    src={mobile}
                                    alt="Mobile Outline"
                                    width="100%"
                                    height="100%"
                                    // maxWidth={'650px'}
                                />
                                <Center>
                                    <Box
                                        ref={mobBoxRef}
                                        position="absolute"
                                        top="7%"
                                        left="27.5%"
                                        width="45%"
                                        height="86%"
                                        bg="blue.500">
                                        <ScreenApp
                                            screenValues={screenValues}
                                        />
                                    </Box>
                                </Center>
                            </Box>
                        </>
                    ))}
            </Box>
        </Center>
        // </ChakraProvider>
    );
}
