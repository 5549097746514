import { useEffect } from 'react';
import {
    Button,
    Box,
    Text,
    Flex,
    ChakraBaseProvider,
    ChakraProvider,
    Link,
} from '@chakra-ui/react';
import { ToastContainer } from 'react-toastify';
import { useSearchParams, useNavigate } from 'react-router-dom';

const SignedUp = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const param = searchParams.get('signedUp');

    // useEffect(() => {
    //     if (param !== 'signed') {
    //         navigate('/auth/register/signed');
    //     }
    // }, [navigate, param]);

    return (
        <ChakraProvider>
            <Flex
                // maxWidth="container.sm"
                mx="auto"
                textAlign="center"
                h="100vh"
                align="center"
                justify="center">
                <Text fontSize="xl" fontWeight="bold" mb="4">
                    A temporary password has been sent to your registered email.
                    Click{' '}
                    <Link href="/login" color="blue.500">
                        here
                    </Link>{' '}
                    to Login.
                </Text>
            </Flex>
        </ChakraProvider>
    );
};

export default SignedUp;
