import React, { useEffect, useState, useContext } from 'react';
import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Select,
    Table,
    Thead,
    Tbody,
    Flex,
    Tr,
    Th,
    Td,
    Text,
    IconButton,
    Box,
    Container,
    ChakraProvider,
    Divider,
    Icon,
    Collapse,
    Badge,
    Heading,
} from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import './Pagination.css';
import { format } from 'date-fns';

import { AuthContext } from './utils/AuthContext';
import Header from './Header';

export default function PageTracker() {
    const { getPagesRecord, pages, pageVisits } = useContext(AuthContext);
    const [filterPage, setFilterPage] = useState('');
    const [page, setPage] = useState(0);

    useEffect(() => {
        getPagesRecord();
    }, []);

    const handleFilterChange = (e) => {
        setFilterPage(e.target.value);
    };

    const handleFilterApply = () => {
        getPagesRecord({
            pageName: filterPage,
        });
        setPage(0);
    };

    const handleFilterReset = () => {
        setFilterPage('');
        getPagesRecord({
            reset: '1',
        });
    };

    const formatDateTime = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDateTime = format(date, 'PPpp'); // Format the date and time
        return formattedDateTime;
    };

    return (
        <ChakraProvider>
            {/* <Sidebar /> */}

            <Header />

            {/* Main Content */}
            <Box
                as="main"
                position="fixed"
                top="64px"
                // left={{ base: '30px', lg: '60px' }}
                left="0"
                right="0"
                bottom="0"
                overflow="auto"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                    '-ms-overflow-style': 'none', // IE and Edge
                    'scrollbar-width': 'none', // Firefox
                }}
                p={4}>
                <Heading size="md" mb={4}>
                    Page Visits
                </Heading>
                <Flex
                    mb={4}
                    w={{ base: '100%', sm: '100%', lg: '50%' }}
                    alignItems="center">
                    <Select
                        onChange={handleFilterChange}
                        value={filterPage}
                        mr={4}>
                        <option value="">All</option>
                        <option value="home">Home</option>
                        <option value="signup">Signup</option>
                        <option value="login">Login</option>
                        <option value="settings">Settings</option>
                        <option value="documents">Documents</option>
                        <option value="chat_history">Chat History</option>
                    </Select>
                    <Button
                        onClick={handleFilterApply}
                        colorScheme="blue"
                        mr={2}>
                        Filter
                    </Button>
                    <Button onClick={handleFilterReset} colorScheme="gray">
                        Reset
                    </Button>
                </Flex>
                <Box
                    overflow="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none', // IE and Edge
                        'scrollbar-width': 'none', // Firefox
                    }}
                    minHeight={'550px'}
                    maxHeight="calc(100vh - 220px)"
                    // border="1px solid gray"
                    // borderRadius="md"
                >
                    <Table variant="simple" size="md" minWidth="800px">
                        <Thead
                            position="sticky"
                            top={0}
                            zIndex={1}
                            bg="white"
                            borderBottom="2px solid"
                            borderColor="gray.200">
                            <Tr>
                                <Th>Time Stamp</Th>
                                <Th>IP Address</Th>
                                <Th>URL</Th>
                                <Th>Page Name</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {pageVisits?.map((x, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <Tr>
                                            <Td>
                                                <Text fontWeight={'bold'}>
                                                    {formatDateTime(
                                                        x.timestamp
                                                    )}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Text fontWeight={'bold'}>
                                                    {x.ip_address}
                                                </Text>
                                            </Td>
                                            <Td>
                                                <Box
                                                    maxWidth="400px"
                                                    whiteSpace="pre-wrap"
                                                    wordWrap="break-word">
                                                    <Text fontWeight={'bold'}>
                                                        {x.url}
                                                    </Text>
                                                </Box>
                                            </Td>
                                            <Td>
                                                <Text fontWeight={'bold'}>
                                                    {x.page_name}
                                                </Text>
                                            </Td>
                                        </Tr>
                                    </React.Fragment>
                                );
                            })}
                        </Tbody>
                    </Table>
                </Box>
                <Box w="100%" mt={'2'}>
                    {pages > 1 && (
                        <Box size="sm">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                previousLabel="Previous"
                                forcePage={page}
                                onPageChange={(e) => {
                                    setPage(e.selected);
                                    localStorage.setItem(
                                        'pagination',
                                        e.selected
                                    );
                                    getPagesRecord({
                                        page: e.selected + 1,
                                        pageName: filterPage,
                                    });
                                }}
                                pageRangeDisplayed={8}
                                pageCount={pages} // Replace with your total number of pages
                                marginPagesDisplayed={2}
                                containerClassName="pagination"
                                activeClassName="active"
                                // previousLinkClassName={page === 0 ? "disabled" : ""}
                                // nextLinkClassName={page === 9 ? "disabled" : ""}
                                breakClassName="break"
                                size="sm"
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </ChakraProvider>
    );
}
