import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    Select,
    Table,
    Thead,
    Tbody,
    Flex,
    Tr,
    Th,
    Td,
    Text,
    IconButton,
    Box,
    Container,
    ChakraProvider,
    Divider,
    Icon,
    Link,
    Spinner,
    Heading,
} from '@chakra-ui/react';
import { TiPencil, TiTrash } from 'react-icons/ti';

import { useEffect, useContext, useState, useRef } from 'react';
// import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar';
import Header from './Header';
import { AuthContext } from './utils/AuthContext';
import { BASE_URL } from './utils/config';

const Documents = () => {
    const {
        docs,
        getDocuments,
        addDocuments,
        deleteDocument,
        toastrMsg,
        toastrError,
        setToastrMsg,
        setToastrError,
        addPageRecord,
    } = useContext(AuthContext);
    const [name, setName] = useState('');
    const [id, setId] = useState('');
    const [docName, setDocName] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('1');
    const [designation, setDesignation] = useState('');
    const [department, setDepartment] = useState('');
    const [employeeId, setEmployeeId] = useState('');
    const [mode, setMode] = useState('');
    const [fadeEmail, setFadeEmail] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    // const [headerHeight, setHeaderHeight] = useState(null);
    const [clientBtnHeight, setClientBtnHeight] = useState(null);
    const [totalHeight, setTotalHeight] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState(null);

    const {
        isOpen: isDelOpen,
        onOpen: onDelOpen,
        onClose: onDelClose,
    } = useDisclosure();

    const headerRef = useRef();
    const sidebarRef = useRef();
    const headingRef = useRef();
    const buttonRef = useRef();
    const addClientRef = useRef();

    // useEffect(() => {
    //     document.title = 'Users';
    //     getUsersList();
    // }, []);

    useEffect(() => {
        addPageRecord('documents', window.location.href);
        getDocuments();
    }, []);

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setIsLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    const handleSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();
        if (!files) return;

        for (let i = 0; i < files.length; i++) {
            formData.append(`${files[i].name}`, files[i]);
        }

        addDocuments(formData);
        onClose();
        setIsLoading(true);
    };

    const handleDel = (e) => {
        e.preventDefault();
        deleteDocument(id, docName);
        onDelClose();
        setIsLoading(true);
    };

    const onSidebarClose = () => setIsSidebarOpen(false);
    const onSidebarOpen = () => setIsSidebarOpen(true);

    return (
        <ChakraProvider>
            <ToastContainer />
            {/* <Sidebar ref={sidebarRef} /> */}
            <Header ref={headerRef} />
            {isLoading && (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="rgba(255, 255, 255, 0.8)"
                    zIndex="9999">
                    <Spinner size="xl" />
                </Box>
            )}
            <Box
                as="main"
                position="fixed"
                top="64px"
                // left={{ base: '30px', lg: '60px' }}
                left="0"
                right="0"
                bottom="0"
                p={4}>
                <Heading size="md" mb={4} ref={headingRef}>
                    Documents
                </Heading>
                <Box

                // border="1px solid gray"
                // borderRadius="md"
                >
                    <Flex justifyContent="flex-end" py="5" ref={addClientRef}>
                        <Button
                            ref={buttonRef}
                            size="sm"
                            marginLeft="auto"
                            colorScheme="blue"
                            onClick={onOpen}>
                            Add Documents
                        </Button>
                    </Flex>

                    <Box
                        overflow="auto"
                        css={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '-ms-overflow-style': 'none', // IE and Edge
                            'scrollbar-width': 'none', // Firefox
                        }}
                        minHeight={'300px'}
                        maxHeight="calc(100vh - 220px)">
                        <Table
                            variant="striped"
                            colorScheme="gray"
                            maxW="100%"
                            overflow="auto"
                            css={{
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                                '-ms-overflow-style': 'none', // IE and Edge
                                'scrollbar-width': 'none', // Firefox
                            }}>
                            <Thead
                                position="sticky"
                                top="0"
                                zIndex="unset"
                                bgColor="white">
                                <Tr>
                                    <Th>Document</Th>

                                    <Th textAlign="center">Action</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {docs &&
                                    docs.map((doc) => (
                                        <Tr key={doc.id}>
                                            <Td>
                                                <Link
                                                    fontWeight="bold"
                                                    color="blue.500"
                                                    target="_blank"
                                                    href={`${BASE_URL}${doc.doc}`}>
                                                    {doc.doc.replace(
                                                        '/api/media/docs/',
                                                        ''
                                                    )}
                                                </Link>
                                            </Td>

                                            <Td>
                                                <Flex
                                                    justify="center"
                                                    align="center">
                                                    <Icon
                                                        as={TiTrash}
                                                        cursor="pointer"
                                                        color="red"
                                                        onClick={() => {
                                                            setId(doc.id);
                                                            setDocName(doc.doc);
                                                            onDelOpen();
                                                        }}
                                                    />
                                                </Flex>
                                            </Td>
                                        </Tr>
                                    ))}
                            </Tbody>
                        </Table>
                    </Box>
                </Box>
            </Box>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add Documents</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <Input
                                type="file"
                                multiple={true}
                                onChange={(e) => {
                                    setFiles(e.target.files);
                                }}
                                required
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            colorScheme="blue"
                            mr={3}
                            onClick={handleSubmit}>
                            Submit
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            <Modal isOpen={isDelOpen} onClose={onDelClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete User</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>
                            Are you sure you want to delete{' '}
                            <strong style={{ color: 'red' }}>{name}</strong>?
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={handleDel}>
                            Delete
                        </Button>
                        <Button onClick={onDelClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </ChakraProvider>
    );
};

export default Documents;
