import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    Text,
    IconButton,
    Box,
    Container,
    ChakraProvider,
    Heading,
    Spinner,
} from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import { format } from 'date-fns';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { TiPencil, TiTrash } from 'react-icons/ti';
import { AuthContext } from './utils/AuthContext';
import React, { useEffect, useContext, useState, useRef } from 'react';
// import { AuthContext } from 'utils/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './Sidebar';
import Header from './Header';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import './Pagination.css';

const ChangePassword = () => {
    // const cred = JSON.parse(localStorage.getItem('userInfo'))?.cred;
    const {
        resetPassword,
        toastrMsg,
        setToastrMsg,
        toastrError,
        setToastrError,
        checkUserPassword,
        cred,
    } = useContext(AuthContext);

    const [currentPassword, setCurrentPassword] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [passStrength, setPassStrength] = useState(1);

    useEffect(() => {
        checkUserPassword();
    }, []);

    useEffect(() => {
        if (cred && cred === 'no') {
            setCurrentPassword('dummy');
        }
    }, [cred]);

    function checkPasswordStrength(password) {
        // Define regex patterns to check for uppercase, lowercase, numbers, and special characters
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumbers = /\d/.test(password);
        const hasSpecialChars = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(
            password
        );

        // Check the length of the password
        const isLengthValid = password.length >= 8;

        // Determine the strength based on the criteria
        if (
            hasUppercase &&
            hasLowercase &&
            hasNumbers &&
            hasSpecialChars &&
            isLengthValid
        ) {
            setPassStrength(3);
        } else if (
            (hasUppercase || hasLowercase) &&
            hasNumbers &&
            isLengthValid
        ) {
            setPassStrength(2);
        } else {
            setPassStrength(1);
        }
    }

    function checkComplete() {
        if (password1 !== '' && password2 !== '' && currentPassword !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    useEffect(() => {
        if (toastrMsg) {
            toast.success(toastrMsg);
            setToastrMsg(null);
            setCurrentPassword('');
            setPassword1('');
            setPassword2('');
        } else if (toastrError) {
            toast.error(toastrError);
            setToastrError(null);
        }
        setIsLoading(false);
    }, [toastrMsg, toastrError, setToastrMsg, setToastrError]);

    function handleClick() {
        setIsLoading(true);
        if (currentPassword !== '' && password1 !== '' && password2 !== '') {
            if (password1 !== password2) {
                toast.error('Passwords did not match');
                setIsLoading(false);
            } else if (password1 === password2 && passStrength === 1) {
                toast.error(
                    'Your password is weak. Set a strong password of alteast 8 characters long with a combination of one upper case, one lower case, one number and one special character'
                );
                setIsLoading(false);
            } else if (password1 === password2) {
                resetPassword(currentPassword, password1, cred);
            }
        }
    }

    useEffect(() => {
        checkComplete();
    }, [currentPassword, password1, password2]);

    return (
        <ChakraProvider>
            <ToastContainer />
            <Header />
            {cred ? (
                <Box
                    as="main"
                    position="fixed"
                    top="64px"
                    left="0"
                    right="0"
                    bottom="0"
                    overflow="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                        '-ms-overflow-style': 'none', // IE and Edge
                        'scrollbar-width': 'none', // Firefox
                    }}
                    p={4}>
                    <Heading size="md" mb={4}>
                        Change Password
                    </Heading>

                    <Flex justify="center" align="center" mt="50px">
                        <Flex
                            w={{
                                base: '90vw',
                                sm: '90vw',
                                md: '70vw',
                                lg: '70vw',
                                xl: '40vw',
                            }}
                            direction="column"
                            p={{ base: '4', xl: '8' }}
                            rounded="md"
                            shadow="md"
                            bg="white">
                            <FormControl
                                mt="4"
                                hidden={
                                    (cred === 'yes' && false) ||
                                    (cred === 'no' && true)
                                }>
                                <FormLabel>Current Password</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <LockIcon color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                        type="password"
                                        autoComplete="current-password"
                                        value={currentPassword}
                                        onChange={(event) => {
                                            setCurrentPassword(
                                                event.target.value
                                            );
                                            // checkComplete();
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>
                            {password1 && (
                                <Text
                                    mt="2"
                                    mb="4"
                                    color="gray.500"
                                    fontSize="sm">
                                    Password Strength:{' '}
                                    {passStrength === 1 && (
                                        <Text
                                            as="span"
                                            color="red.500"
                                            fontWeight="bold">
                                            Weak
                                        </Text>
                                    )}
                                    {passStrength === 2 && (
                                        <Text
                                            as="span"
                                            color="yellow.500"
                                            fontWeight="bold">
                                            Moderate
                                        </Text>
                                    )}
                                    {passStrength === 3 && (
                                        <Text
                                            as="span"
                                            color="green.500"
                                            fontWeight="bold">
                                            Strong
                                        </Text>
                                    )}
                                </Text>
                            )}
                            <FormControl mt="4">
                                <FormLabel>New Password</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <LockIcon color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                        type="password"
                                        autoComplete="new-password"
                                        value={password1}
                                        onChange={(event) => {
                                            setPassword1(event.target.value);
                                            // checkComplete();
                                            checkPasswordStrength(
                                                event.target.value
                                            );
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl mt="4">
                                <FormLabel>Re-Enter Password</FormLabel>
                                <InputGroup>
                                    <InputLeftElement pointerEvents="none">
                                        <LockIcon color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                        type="password"
                                        autoComplete="new-password"
                                        value={password2}
                                        onChange={(event) => {
                                            setPassword2(event.target.value);
                                            // checkComplete();
                                        }}
                                    />
                                </InputGroup>
                            </FormControl>
                            <Button
                                isLoading={isLoading}
                                mt="8"
                                mx="auto"
                                colorScheme="blue"
                                isDisabled={disabled}
                                onClick={() =>
                                    handleClick(password1, password2)
                                }>
                                Set Password
                            </Button>
                        </Flex>
                    </Flex>
                </Box>
            ) : (
                <Box
                    position="fixed"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="rgba(255, 255, 255, 0.8)"
                    zIndex="9999">
                    <Spinner size="xl" />
                </Box>
            )}
        </ChakraProvider>
    );
};

export default ChangePassword;
